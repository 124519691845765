import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import networkgraph from "highcharts/modules/networkgraph";
import UserBookShelf from "./UserBookshelf";
import Highcharts from "highcharts";
import Header from "./Header";
import { Link } from "react-router-dom";
import {
  CleanCacheDataPersonalized,
  ErrorBoundary,
  KnowledgeData,
  KnowledgeDataBook,
} from "./MasterFile";
import { useParams } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ReactGA from "react-ga";
import "./ShowMore.scss";

import Typesense from "typesense";
const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

let window_size = window.innerWidth;

var default_search = require("./CacheData/default_search_dataset.json");
var col1 = "#B9A7BB",
  col2 = "#C9D9F0",
  col3 = "#808080",
  col4 = "#8C9B88";
// const client = typesense_client();
const client = new Typesense.Client({
  nodes: [
    {
      host: "g723ywvq0htdfronp-1.a1.typesense.net",
      port: "443",
      protocol: "https",
    },
  ],
  apiKey: "UBEu2Rlx7dRWUbD0kHaoewh0b0alGKvG",
});
const index = client.collections("books");
const searchSuggestions = (inputValue) => {
  return client
    .collections("books")
    .documents()
    .search({
      q: inputValue,
      query_by: "book_title_goodreads",
    })
    .then((response) => {
      return response.hits.map((result) => {
        return {
          value: result.document.image_name2,
          label: result.document.book_title_goodreads,
          image: result.document.image_name2,
        };
      });
    });
};

// console.log(searchSuggestions)

let placeholder = "Search for a Book or Click on a Bubble...";

if (window_size < 800) {
  placeholder = "Search here or Click on a Bubble...";
}

const SearchInput = () => {
  return (
    <AsyncSelect
      defaultOptions={default_search}
      cacheOptions
      loadOptions={searchSuggestions}
      placeholder={placeholder}
      onChange={(e) => window.open(e.value + "-" + e.label, "_self")}
      formatOptionLabel={(image_data) => (
        <div className="country-option">
          <img
            src={
              "https://ik.imagekit.io/bookclub/" +
              image_data.image +
              ".jpg?tr=w-120,h-180"
            }
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "8%",
              marginRight: "10px",
            }}
            alt="."
          />
          <span>{image_data.label} </span>
        </div>
      )}
      // onBlurResetsInput={false}
    />
  );
};
function Tool(book_list, author_list, book_page_list, genre_list) {
  let TOOLTIP = {
    style: {
      pointerEvents: "auto",
      width: "180px",
      whiteSpace: "normal",
    },
    formatter: function () {
      var info = "a";

      if (author_list[book_list.indexOf(this.key)].length > 0) {
        var info =
          " <b>by </b>" +
          author_list[book_list.indexOf(this.key)] +
          "<br><br>" +
          genre_list[book_list.indexOf(this.key)] +
          "<br> <br>  <a target='_blank' href=" +
          "/book/" +
          book_page_list[book_list.indexOf(this.key)] +
          "><span style='color: #FF0000'><b>Book Detail Page </b> </span></a>";
        return this.key + "<br> " + info;
      }
    },
  };
  return TOOLTIP;
}
function Nodes(
  book_list,
  book_page_list,
  base_name,
  base_url,
  num_ratings_list
) {
  let min_votes = Math.log(Math.min(...num_ratings_list));
  let max_votes = Math.log(Math.max(...num_ratings_list));

  let multiplier = (window_size + 500) / 60;

  let colors = [col1, col2, col3, col4];
  let node_list_knowledge_graph = [];
  let half_way_mark = ~~(book_page_list.length / 2);
  for (let i = 0; i < book_page_list.length; i++) {
    if (i == ~~(0.8 * half_way_mark)) {
      node_list_knowledge_graph.push({
        id: "",
        name: "",
        marker: {
          radius: 70,
          symbol: base_url,
        },
      });
    } else {
      node_list_knowledge_graph.push({
        id: book_page_list[i] + "-" + book_list[i],
        name: book_list[i],
        marker: {
          radius: Math.max(
            (multiplier * (Math.log(num_ratings_list[i]) - min_votes)) /
              (max_votes - min_votes),
            2
          ),
        },
        color: colors[0],
      });
    }
  }
  let NODES = [
    {
      cursor: "pointer",
      marker: {
        states: {
          hover: {
            enabled: true,
            radius: 20,
          },
        },
      },
      states: {
        hover: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: true,
        linkFormat: "",
      },

      nodes: node_list_knowledge_graph,
    },
  ];
  return NODES;
}
const handlePointClick = (e) => {
  // console.log(e.point.id)
  window.open(e.point.id, "_self");
  // window.open("www.bookclub.ai","_self");
};
let PLOT = {
  networkgraph: {
    keys: ["from", "to"],
    point: {
      events: {
        click(e) {
          handlePointClick(e);
        },
        mouseOver(e) {},
      },
    },
    layoutAlgorithm: {
      enableSimulation: true,
      integration: "verlet",

      friction: -0.95,
    },
  },
};
function LeftSideTextContainer() {
  return (
    <div
      style={{
        left: 0,
        marginTop: "3em",
        marginLeft: "1.5em",
        color: "grey",
        // bottom: 0,
        width: "10%",
        overflow: "auto",
      }}
    >
      Bubbles are arranged radially around the central book to show its
      relevance based on genres, user reviews and authors. <br></br>
      <br></br>Size of a bubble denotes the popularity of a given book based on
      goodreads votes.
    </div>
  );
}
function Chart(
  book_list,
  author_list,
  book_page_list,
  genre_list,
  num_ratings_list,
  base_name,
  base_url
) {
  let options = {
    chart: {
      type: "networkgraph",
      // height: "40%",
      // Set the chart to be responsive
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: "45%",
            },
          },
        },
        {
          breakpoint: 1000,
          options: {
            chart: {
              height: "45%",
            },
          },
        },
        {
          breakpoint: 800,
          options: {
            chart: {
              height: "85%",
            },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              height: "85%",
            },
          },
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              height: "85%",
            },
          },
        },
      ],
    },
    title: {
      text: "",
    },
    tooltip: Tool(book_list, author_list, book_page_list, genre_list),
    plotOptions: PLOT,
    series: Nodes(
      book_list,
      book_page_list,
      base_name,
      base_url,
      num_ratings_list
    ),
  };
  return options;
}

function KnowledgeGraph() {
  const [selectedOption, setSelectedOption] = useState("none");
  const params = useParams();
  const kg_id = params["id"]?.split("-")[0];

  const [book_list, author_list, book_page_list, genre_list, num_ratings_list] =
    KnowledgeDataBook(kg_id, window_size);
  let base_name = kg_id;

  let image_height = 180;
  let image_width = 120;
  let header_font_size = "2.5vw";
  if (window_size < 800) {
    image_height = 120;
    image_width = 80;
    header_font_size = "5vw";
  }
  let base_url =
    "url(https://ik.imagekit.io/bookclub/" +
    kg_id +
    ".jpg?tr=w-" +
    image_width +
    ",h-" +
    image_height +
    ")";

  if (book_list.length == 0) {
    return (
      <>
        <div>
          <Header />{" "}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>
          <Header />
          <div style={{ margin: "20px 10px 20px 20px" }}>
            <h1 style={{ fontSize: header_font_size }}>
              Find Your Next Great Read with Our Interactive Mind Map.
            </h1>
          </div>
          <div style={{ margin: "20px" }}>{SearchInput()}</div>
          <ErrorBoundary>
            {/* {LeftSideTextContainer()} */}
            <div>
              <div style={{ color: "grey" }}>
                <h10 style={{ margin: "22px" }}>
                  Size of a bubble denotes the book's popularity.
                </h10>
              </div>
              <div className="Mind-Map">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={Chart(
                    book_list,
                    author_list,
                    book_page_list,
                    genre_list,
                    num_ratings_list,
                    base_name,
                    base_url
                  )}
                />
              </div>
              <span>
                {" "}
                <p class="LazyPeopleSearch3">
                  {" "}
                  <span class="LazyPeopleSearch4">Top Searches:{"    "}</span>
                  {"  "}
                  <a
                    href="51353885-The%20Great%20Gatsby"
                    target="_self"
                    style={{ textDecoration: "underline", color: "grey" }}
                  >
                    {" "}
                    {" " + "The Great Gatsby"}
                  </a>{" "}
                  <span>{" , "}</span>
                  <a
                    href="41433284-Bitcoin%20Billionaires:%20A%20True%20Story%20of%20Genius,%20Betrayal,%20and%20Redemption"
                    target="_self"
                    style={{ textDecoration: "underline", color: "grey" }}
                  >
                    {" "}
                    {" " + "Bitcoin Billionaires"}
                  </a>
                  <span>{" , "}</span>
                  <a
                    href="71368-Dragon%20Rider"
                    target="_self"
                    style={{ textDecoration: "underline", color: "grey" }}
                  >
                    {" "}
                    {" " + "Dragon Rider"}
                  </a>
                  <span>{" , "}</span>
                  <a
                    href="10818853-Fifty%20Shades%20of%20Grey"
                    target="_self"
                    style={{ textDecoration: "underline", color: "grey" }}
                  >
                    {" "}
                    {" " + "Fifty Shades of Grey"}
                  </a>
                  <span>{"  , "}</span>
                  <a
                    href="106835-The%20Intelligent%20Investor"
                    target="_self"
                    style={{ textDecoration: "underline", color: "grey" }}
                  >
                    {" "}
                    {" " + "The Intelligent Investor"}
                  </a>
                </p>
              </span>
            </div>
          </ErrorBoundary>
        </div>
      </>
    );
  }
}
export default KnowledgeGraph;
