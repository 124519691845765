/*
This file is for Book Series Page
*/
import React, { useState } from "react";
import Header from "./Header";
import { useRef } from "react";
import "./ShowDetails.scss";
import { NavSeries } from "./Nav";
import requests from "./request";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { FiChevronRight } from "react-icons/all";
import { ErrorBoundary, combine_images } from "./MasterFile";
import { responsive_series } from "./MasterFile";
import { NonDetailPageAPI } from "./MongoDB";
import "./Nav.scss";
import { red } from "@material-ui/core/colors";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

export function camelize(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function series_carousel_render(name, series_data, ref) {
  return (
    <>
      <div className="nav">
        <carousel_header>
          {name}
          {/* <Link to={"/series/all/" + name.toLowerCase()}>
            <span class="name_font">
              {" "}
              Show all <FiChevronRight />
            </span>
          </Link> */}
        </carousel_header>
      </div>

      {/* ToDo- Update below code as reusable component in MasterFile  */}
      <div className="carousel_class_series">
        <Carousel
          partialVisible={false}
          itemClass="carousel-item-padding-40-px"
          responsive={responsive_series}
          ssr={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {series_data.map((book) => item_book_series(book, ref))}
        </Carousel>
      </div>
    </>
  );
}

/* 
Input: All series data
Output: Series data and carousels separately
<<carousel_names.length>> implies number of carousels rendered in selected genre
*/
const carousel_series = (series_data, carousel_names, ref) => {
  let td = [];
  for (let i = 0; i < carousel_names.length; i++) {
    td.push(
      series_carousel_render(
        carousel_names[i],
        series_data.filter(
          (carousel) => carousel.carousel === carousel_names[i]
        ),
        ref
      )
    );
  }

  return td;
};

/*
InputData- Individual BookSeries data 
OutputData-Actual Items within carousel
Description- Lists within the carousel including card, image, collage and description. 
*/

const item_book_series = (books, ref) => {
  let td = [];

  let list_name = [];
  list_name.push(books["series_images"]);

  list_name = list_name[0].replace("'", "").split(",");

  for (let i = 0; i < 1; i++) {
    td.push(
      <div className="card-collage-series">
        <Link to={"/series/" + books["book_series_name"]?.replace(/ /g, "-")}>
          <div class="grid-container-collage">
            {/* ToDo- Update below code in a loop rather than duplication  */}
            {combine_images(list_name)}
          </div>
        </Link>

        <h4 class="card-title-Series">{camelize(books["book_series_name"])}</h4>
      </div>
    );
  }

  return td;
};

const Series = () => {
  const [selectedOption, setselectedOption] = useState(
    requests.fetchFantasySeries
  );
  const ref = useRef();
  let series_page_results = NonDetailPageAPI(selectedOption);

  // ToDo- Update below code to reflect KeyValue pairs
  let series_data = [];
  let initial_loop = 20;

  for (var i = 0; i < initial_loop; i++) {
    if (series_page_results === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(series_page_results["books_list"]).length;
      series_data.push(series_page_results["books_list"][i]);
    }
  }

  let carousel_names = series_data
    .map((item) => item.carousel)
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div>
      <Header />
      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        <Helmet>
          <title>{"Bookclub.ai| Meet your next book"}</title>
          <meta
            name="bookseries"
            content={
              "Explore popular book-series from your favourite genres with powerful Machine Learning based recommendations. Be part of the growing booklovers community on Bookclub.ai"
            }
          />
        </Helmet>

        <ErrorBoundary>
          <div className="detail__cast">
            <div style={{ margin: "10px 10px 30px 20px" }}>
              <h4>Browse Popular Series by Categories on BookClub.ai</h4>
            </div>
            <h4
              onClick={() => setselectedOption(requests.fetchFantasySeries)}
            ></h4>

            <NavSeries setselectedOption={setselectedOption} />

            {carousel_series(series_data, carousel_names, ref)}
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Series;
