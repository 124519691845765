import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./AboutUs";
import ReactGA from "react-ga";
import TermsOfUse from "./TermsOfUse";
import { SageMaker } from "aws-sdk";
// import { Feedback } from "@material-ui/icons";
import Feedback from "./Feedback";
const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

export default function Footer() {
  return (
    <MDBFooter bgColor="dark" className="text-center text-lg-start">
      <section className="d-flex justify-content-center justify-content-lg-between p-1">
        <div>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="twitter" />
          </a>
          <a href="" className="me-4 text-reset">
            <MDBIcon fab icon="linkedin" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <MDBIcon icon="gem" className="me-3" />
                Bookclub.ai
              </h6>
              <p>
                Our mission is to apply AI advancements to the benefit of the
                book community.
              </p>
              <p>
                AI behind bookclub.ai |{" "}
                <a
                  href="https://medium.com/@19aditiyadav/how-i-built-bookclub-ai-ai-powered-book-discovery-website-8f66679f32db"
                  target="_blank"
                >
                  Read More
                </a>
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              {/* <h6 className='text-uppercase fw-bold mb-4'>Products</h6>  */}
              <p>
                <a href="./AboutUs" className="text-reset">
                  About Us
                </a>
              </p>
              <p>
                <a href="./Feedback" className="text-reset">
                  Feedback
                </a>
              </p>
              <p>
                <a href="./TermsOfUse" className="text-reset">
                  Terms of Use
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Belltown, Seattle, United States
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                19aditiyadav@gmail.com
              </p>
              {/* <p>
                <MDBIcon icon="phone" className="me-3" /> +1-206-228-4232
              </p> */}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(245, 245, 245)" }}
      >
        © 2023 Copyright:Bookclub.ai
      </div>
    </MDBFooter>
  );
}
