import React from "react";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');


const GoogleAnalytics = (category="About Us") => {
  const eventTracker = (action = "About Us", label = "About Us") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default GoogleAnalytics;