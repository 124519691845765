import React from "react";
import Iframe from "react-iframe";
import Header from "./Header";

const Feedback = () => {
  return (
    // <div>
    //   <div
    //     data-tf-widget="ZGiDnkuV"
    //     data-tf-opacity="100"
    //     data-tf-iframe-props="title=Bookclub.ai Feedback form"
    //     data-tf-transitive-search-params
    //     data-tf-medium="snippet"
    //     style="width:100%;height:500px;"
    //   ></div>
    //   <script src="//embed.typeform.com/next/embed.js"></script>
    // </div>
      <>
          <Header />
    <Iframe
      url="https://sdswnxl5wn9.typeform.com/to/ZGiDnkuV"
      width="100%"
      height="500px"
      frameBorder="0"
      title="Bookclub.ai Feedback form"
    /></>
  );
};

export default Feedback;