import React, { useEffect, useState } from "react";
import { ReactLocation, useMatch } from "react-location";
import "./App.css";
import ReactDOM from "react-dom";
// import Nav from "./Nav";
import ReactGA from "react-ga";
import Home from "./Home";
import Celebrity from "./Celebrity";
import Lists from "./Lists";
import Series from "./Series";
import KnowledgeGraph from "./KnowledgeGraph";
import ShowDetails from "./Detailpage";
import CelebrityDetailPage from "./CelebrityDetailPage";
import ListPage from "./ListDetailPage";
import SeriesPage from "./SeriesDetailPage";
import GenrePage from "./GenrePage";
import GenreDetailPage from "./GenreDetailPage";
import SeriesParent from "./SeriesParent";
import Search_App from "./Search/search_main";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Community from "./Community";
import ProfilePage from "./ProfilePage";
import UserShelfDetailPage from "./UserBooksShowAll";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import TermsOfUse from "./TermsOfUse";
import LoginPage from "./Login";
import Navbar from "./Login";
import Discover from "./Discover";
import { CommunityDefault } from "./Community";
import * as Realm from "realm-web";
import axios from "axios";
import { ErrorBoundary } from "./MasterFile";
import Header from "./Header";
import Masthead from "./Masthead";
import { GenresHome } from "./ListHome";
import CelebrityHome from "./CelebrityHome";
import ListHome from "./ListHome";
import { items_genres, myBreakpointsAndColsGenres } from "./ListHome";
import Masonry from "react-masonry-css";
import Feedback from "./Feedback";
// import KnowledgeGraphHome from "./KGraphHome";

const reactLocation = new ReactLocation();

// const Page404 = () => <h1>Four:oh:four</h1>

// const Celebrity = React.lazy(() => import('./Celebrity'));

const tagManagerArgs = {
  gtmId: "<GTM-P37DVTH>",
};
TagManager.initialize(tagManagerArgs);

ReactGA.initialize("GTM-P37DVTH");

ReactGA.pageview(window.location.pathname + window.location.search);

let window_size = window.innerWidth;
let height_hall_of_fame = 630;

if (window_size < 900) {
  height_hall_of_fame = 340;
}

function App() {
  const helmetContext = {};
  window.dataLayer.push({
    event: "pageview",
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set the loading state to false after a short delay
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []); // The empty array ensures that the effect only runs on mount

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <div className="App">
      <HelmetProvider context={helmetContext}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                loading ? (
                  <>
                    <Header />
                    <div
                      className="w-auto p-3"
                      style={{ backgroundColor: "#eee" }}
                    >
                      {/* <NavHome setselectedOption={setselectedOption}/> */}
                      <ErrorBoundary>
                        {Masthead()}
                        {CelebrityHome()}
                        {GenresHome()}

                        {ListHome()}
                        {/* {KnowledgeGraphHome()} */}
                      </ErrorBoundary>
                    </div>
                  </>
                ) : (
                  <Home />
                )
              }
            />

            <Route path="/home" element={<Home />} />
            <Route path="/celebrity" element={<Celebrity />} />
            <Route path="/lists" element={<Lists />} />
            <Route path="/series" element={<Series />} />
            <Route path="/genres" element={<GenrePage />} />
            <Route
              path="/MindMap/:id"
              element={
                <KnowledgeGraph key={reactLocation.key}></KnowledgeGraph>
              }
            />
            <Route path="/book/:id" element={<ShowDetails />} />
            {/* <Route component={Page404} /> */}
            <Route path="/celebrity/:id" element={<CelebrityDetailPage />} />
            <Route path="/lists/:id" element={<ListPage />} />
            <Route path="/series/:id" element={<SeriesPage />} />
            <Route path="/series/all/:id" element={<SeriesParent />} />

            <Route path="/genres/all/:id" element={<GenreDetailPage />} />
            <Route
              path="Community/:id"
              element={<CommunityDefault input="Time Travel Romance" />}
            />
            {/* <Route path="ProfilePage" element={<ProfilePage />} /> */}
            {/* <Route path="login" element={<LoginPage />} /> */}
            {/* <Route path="/profile/:id" element={<UserShelfDetailPage />} /> */}
            <Route path="/search" element={<Search_App />} />
            <Route path="/Footer" element={<Footer />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Feedback" element={<Feedback />} />
            <Route path="/TermsOfUse" element={<TermsOfUse />} />
            {/* <Route path="/Discover" element={<Discover />} /> */}
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
