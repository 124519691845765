// This file is for fetching data from MongoDB, Variables and credentials are defined only once and are reusable

import { useState, useEffect } from "react";
// import {cookieValue} from "./MasterFile";

// import * as Realm from "realm-web";
import ReactGA from "react-ga";
import axios from "axios";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');





// const app = new Realm.App({ id: "realmbooks-wigys" });
// const credentials = Realm.Credentials.anonymous();
// const user = app.logIn(credentials);
// console.log(user)
// console.log(Object.keys(app.allUsers).map((key) => [Number(key), app.allUsers[key]]).length)
// let num_users=Object.keys(app.allUsers).map((key) => [Number(key), app.allUsers[key]]).length

// export const client = num_users===0 ?  
//   // wait for 1 second before reloading the page
//   setTimeout(() => {
//     window.location.reload();
//   }, 350) : app.currentUser.mongoClient("mongodb-atlas")


// Rest API For populating Non-Detail Page
  export function NonDetailPageAPI(genre_id) {
    const [info, setInfo] = useState();
  
    const getData = (genre_id) => {
      var config = {
        method: "get",
        url: "https://us-east-1.aws.data.mongodb-api.com/app/realmbooks-wigys/endpoint/bookclubnondetail?secret=test&primary_key_query="+genre_id,
        headers: {},
      };
  
      axios(config).then((req) => setInfo(req["data"]));
    };
  
    useEffect(() => {
      getData(genre_id);
    }, [genre_id]);
    return info;
  }

  // Rest API For populating Non-Detail Page
  export function DetailPageAPI(book_id) {
    const [info, setInfo] = useState();
  
    const getData = (book_id) => {
      var config = {
        method: "get",
        url: "https://us-east-1.aws.data.mongodb-api.com/app/realmbooks-wigys/endpoint/bookclubdata?secret=test&book_id="+book_id,
        headers: {},
      };
  
      axios(config).then((req) => setInfo(req["data"]));
    };
  
    useEffect(() => {
      getData(book_id);
    }, [book_id]);
    return info;
  }



// Write Data on MongoDB for Personalisation Page- This function checks for a User+Category pair({ id: username, category: cat }) 
// and adds a new key in MongoDB if not found, else just append. This function is invoked from MasterFile.js

// export function Write_Mongo(username, cat, value) {
//   let arr=[value]
//   let dCollection = client.db("lazy1").collection("profile");

//   dCollection.updateOne(
//     { id: username, category: cat },
//     { $push: { "books_list": arr[0] } },
//     { upsert: true }
//   );
// }

// Write Data on MongoDB for Personalisation Detail Page

// export function Write_Mongo_Detail(cat, value,title_name) {
//   let arr=[value,title_name]
//   let dCollection = client.db("lazy1").collection("profile");

//   dCollection.updateOne(
//     { id: cookieValue, category: cat },
//     { $push: { "books_list": arr[0],'title':title_name } },
//     { upsert: true }
//   );
// }


// Write Data on MongoDB for Personalisation Browse

// export function Write_Mongo_Browse_History(cat, value) {
//   let arr=[value]
//   let dCollection = client.db("lazy1").collection("profile");

//   dCollection.updateOne(
//     { id: cookieValue, category: cat },
//     { $push: { "books_list": arr[0]} },
//     { upsert: true }
//   );
// }


// export function Detail_Page_Carousel(value) {
//   console.log(value)
//   const [titles, setTitles] = useState();
//   const getData = () => {
//     let dCollection = client.db("lazy1").collection("detail_page");

//     let result = dCollection
//       .find({ seed_title: value })
//       .then((req) => setTitles(req));
//   };

//   useEffect(() => {
//     getData();
//   }, []);
//   return titles;
// }

// export function CelebrityMainPage(selectedOption) {
//   const [titles, setTitles] = useState([selectedOption]);
//   async function test_data(selectedOption) {
//     let dCollection = client.db("lazy1").collection("bookclub");
//     let result = dCollection.find({ primary_key: selectedOption });

//     return result;
//   }

//   useEffect(() => {
//     test_data(selectedOption).then((req) => setTitles(req));
//   }, [selectedOption]);
//   return titles;
// }

// Powers List Main Page
// export function ListMainPage(selectedOption) {
//   const [titles, setTitles] = useState();
//   async function test_data(selectedOption) {
//     let dCollection = client.db("lazy1").collection("bookclub");
//     let result = dCollection.find({ primary_key: selectedOption });
//     return result;
//   }

//   useEffect(() => {
//     test_data(selectedOption).then((req) => setTitles(req));
//   }, [selectedOption]);
//   return titles;
// }


// Powers Profile Page
// export function ListProfilePage(list_category,cookie_id) {

//   const [titles, setTitles] = useState();
//   async function test_data(list_category) {
//     let dCollection = client.db("lazy1").collection("profile");
//     let result = dCollection.find({ category: list_category,id:cookie_id });
//     return result;
//   }

//   useEffect(() => {
//     test_data(list_category).then((req) => setTitles(req));
//   }, [list_category]);
//   return titles;
// }



/* Powers Series Page */

// export function SeriesMainPage(selectedOption) {
//   const [titles, setTitles] = useState();

//   async function test_data(selectedOption) {
//     let dCollection = client.db("lazy1").collection("bookclub");
//     let result = dCollection.find({ primary_key: selectedOption });
//     return result;
//   }

//   useEffect(() => {
//     test_data(selectedOption).then((req) => setTitles(req));
//   }, [selectedOption]);
//   return titles;
// }

/* Powers SeriesParent */
// export function SeriesParentInputJSON(selectedOption) {
//   const [titles, setTitles] = useState();
//   async function test_data(selectedOption) {
//     let dCollection = client.db("lazy1").collection("bookclub");
//     let result = dCollection.find({ primary_key: selectedOption });

//     return result;
//   }

//   useEffect(() => {
//     test_data(selectedOption).then((req) => setTitles(req));
//   }, [selectedOption]);
//   return titles;
// }

/* Powers DetailPage- List,Series,Genres etc
Input is {Key,Value} e.g {ListPage,HarrPotter}
 */
// export function Detailpage(key, value) {
//   const [followers2, setFollowers2] = useState();

//   const getData = () => {
//     let dCollection = client.db("lazy1").collection("bookclub");

//     let result = dCollection
//       .findOne({ [key]: value })
//       .then((req) => setFollowers2(req));
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   return followers2;
// }


// Read List Data for individual users(Show All Page)

// export function DetailPagePersonalized(key, value) {
//   const [followers2, setFollowers2] = useState();

//   const getData = () => {
//     let dCollection = client.db("lazy1").collection("profile");

//     let result = dCollection
//       .findOne({ [key]: value,id:cookieValue })
//       .then((req) => setFollowers2(req));
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   return followers2;
// }

// Personalized- MoreLikeThis based on browse history

// export function MoreLikeThisPersonalized(key, value) {
//   const [followers2, setFollowers2] = useState();

//   const getData = () => {
//     let dCollection = client.db("lazy1").collection("profile");

//     let result = dCollection
//       .findOne({ [key]: value,id:cookieValue })
//       .then((req) => setFollowers2(req));
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   return followers2;
// }


// Genre Knowledge Graph- To fetch data based on search

// export function GenreKnowledge(value) {
//   const [followers2, setFollowers2] = useState();


//   const getData = () => {
//     let dCollection = client.db("lazy1").collection("bookclub");

//     let result = dCollection
//       .findOne({ primary_key: value })
//       .then((req) => setFollowers2(req));
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   return followers2;
// }



// Personalized- For KnowldgeGraphClickable

// export function KnowldgeGraphClickable(kg_id) {
//   const [followers2, setFollowers2] = useState();

//   const getData = () => {
//     let dCollection = client.db("lazy1").collection("detail_page");

//     let result = dCollection
//       .findOne({ seed_title: kg_id })
//       .then((req) => setFollowers2(req));
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   return followers2;
// }


/* Powers DetailPage- List,Series,Genres etc
Input is {Key,Value} e.g {ListPage,HarrPotter}
 */

/* Powers Genre Page */
// export function GenreMainPage(selectedOption) {
//   const [titles, setTitles] = useState();
//   async function test_data(selectedOption) {
//     let dCollection = client.db("lazy1").collection("bookclub");
//     let result = dCollection.find({ primary_key: selectedOption });
//     return result;
//   }

//   useEffect(() => {
//     test_data(selectedOption).then((req) => setTitles(req));
//   }, [selectedOption]);
//   return titles;
// }
