export default {



  //HomePage Navbar
  fetchFiction:"fiction_carousels",
  fetchNonFiction:"nonfiction_carousels",
  fetchYoungAdult:"youngadult_carousels",
    
  // Genre Page NavBar
  fetchRomance: "genre_romance",
  fetchFantasy: "genre_fantasy",
  fetchScienceFiction: "genre_science fiction",
  fetchCrime: "genre_crime",
  fetchHistorical: "genre_historical",
  fetchFamily: "genre_family",
  fetchYoungAdult: "genre_young adult",
  fetchFiction: "genre_fiction",
  fetchTechnology:"genre_technology",
  fetchChildrens:"genre_childrens",
  fetchSuspense:"genre_suspense",
  fetchBusiness:"genre_business",
  fetchBiography:"genre_biography",
  fetchHealth:"genre_health",
  fetchNonFiction:"genre_nonfiction",
  fetchSelfHelp:"genre_selfhelp",
  fetchReligion: "genre_religion",
  fetchShortStories: "genre_shortstories",
  fetchLGBT: "genre_lgbt",

  
  //CelebrityPage Navbar
  fetchMusicians:"musicians",
  fetchEntrepreneurs: "entrepreneurs",
  fetchInvestor:"investors",
  fetchAuthors:"authors",
  fetchActivist: "activists",
  fetchFilmmakers:"filmmakers",
  fetchAthletes:"athletes",
  fetchPoliticians: "politicians",
  fetchChefs:"chefs",
  fetchModels:"models",
  

    //ListPage Navbar
    fetchHobbies:"list_hobbies",
    fetchCultural: "list_cultural",
    fetchTechnologyList:"list_technology",
    fetchRomance:"list_romance",
    fetchHistory: "list_history",
    fetchManga:"list_manga",
    fetchBusinessList:"list_business",
    fetchHealth: "list_health",
  fetchFantasyList: "list_fantasy",
    
  //SeriesPage Navbar


  fetchScienceFictionSeries:"series_science fiction",
  fetchCrimeSeries:"series_crime",
  fetchYASeries: "series_young adult",
  fetchRomanceSeries:"series_romance",
  fetchHistoricalSeries:"series_historical",
  fetchFictionSeries: "series_fiction",
  fetchBiographySeries:"series_biography",
  fetchNFSeries:"series_nonfiction",
  fetchFantasySeries: "series_fantasy",

    
};
