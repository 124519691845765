import React, { useState, Component, useEffect, useRef } from "react";
import Header from "./Header";
import { useParams } from "react-router";
import "./Nav.scss";
import {
  carousel_parse_detail_page,
  ErrorBoundary,
  detail_page_card,
  CleanMongoDBDataAPI
} from "./MasterFile";

import { Helmet } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga";
import { DetailPageAPI } from "./MongoDB";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');




const ShowDetails = ({ book }) => {
  const params = useParams();
  const ref = useRef();

  const book_id = params["id"]?.split("-").pop();

  

  let book_info = DetailPageAPI(book_id);




  const [home_data2, carousel_names2] = CleanMongoDBDataAPI(book_info);


  

  if (!book_info) {
    return (
      <div>
        <Header />
      </div>
    );
  } else {
    return (
      <>
        <Header />
        <Helmet>
          <title>{book_info?.["book_title_goodreads"]+" | Bookclub.ai"}</title>
          <meta
            name="description"
            content={book_info?.["description"]}
          />
        </Helmet>
        <ErrorBoundary>
          <div className="detail_page_card">
            {detail_page_card(book_info)}
          </div>
          <br></br>
          <br></br>
          <div className="carousal_detail_page">
            {carousel_parse_detail_page(home_data2, carousel_names2, ref, "/",book_info?.["book_title_goodreads"])}
          </div>
        </ErrorBoundary>
        <ScrollToTop smooth width={30} color="brown"  viewBox="0 0 256 256"/>
      </>
    );
  }
};

export default ShowDetails;
