/* This page powers List Page */
import React, { useState } from "react";
import Header from "./Header";
import "./ShowDetails.scss";
import Masonry from "react-masonry-css";
import NavLists from "./Nav";
import { Link } from "react-router-dom";
import { NonDetailPageAPI } from "./MongoDB";
import { ErrorBoundary, combine_images } from "./MasterFile";
import requests from "./request";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

/* Defines number of cards in different screen sizes */
const myBreakpointsAndCols = {
  default: 3,
  1100: 2,
  700: 2,
  500: 1,
  300: 1,
};

const items = (list_data) => {
  let list_name = [];
  let list_images = [];
  let list_book_count = [];

  let initial_loop = 5;
  for (var i = 0; i < initial_loop; i++) {
    if (list_data[0] === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(list_data[0]).length;
      list_name.push(list_data[0][i]["list_name"]);
      list_images.push(list_data[0][i]["list_images"]);
      list_book_count.push(list_data[0][i]["number_books"]);
    }
  }

  let td = [];
  for (let i = 0; i < list_name.length; i++) {
    let images_list_array = list_images[i].split(",");
    if (i % 3 == 0) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage">
              <Link to={"/lists/" + list_name[i]}>
                <div class="grid-container-collage">
                  {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                  {combine_images(images_list_array)}
                </div>
              </Link>
              <p class="card-title-Lists">{list_name[i]}</p>
              <p class="card-title">{list_book_count[i] + " Books"}</p>
            </div>
          </div>
        </div>
      );
    }

    if (i % 3 == 1) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage_2">
              <Link to={"/lists/" + list_name[i]}>
                <div class="grid-container-collage">
                  {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                  {combine_images(images_list_array)}
                </div>
              </Link>
              <p class="card-title-Lists">{list_name[i]}</p>
              <p class="card-title">{list_book_count[i] + " Books"}</p>
            </div>
          </div>
        </div>
      );
    }

    if (i % 3 == 2) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage_3">
              <Link to={"/lists/" + list_name[i]}>
                <div class="grid-container-collage">
                  {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                  {combine_images(images_list_array)}
                </div>
              </Link>
              <p class="card-title-Lists">{list_name[i]}</p>
              <p class="card-title">{list_book_count[i] + " Books"}</p>
            </div>
          </div>
        </div>
      );
    }
  }

  return td;
};

/*
Input Data- List data(list images, list name, book count)
Output Data- List Cards
*/
const Lists = () => {
  const [selectedOption, setselectedOption] = useState(requests.fetchRomance);
  /* ToDo- Activate the Click Button */

  let list_page_results = NonDetailPageAPI(selectedOption);

  // let list_page_results = ListMainPage(selectedOption);

  let list_data = [];
  for (var i = 0; i < 1; i++) {
    if (list_page_results === undefined) {
      continue;
    } else {
      list_data.push(list_page_results["books_list"]);
    }
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        <div>
          <Helmet>
            <title>{"Bookclub.ai| Meet your next book"}</title>
            <meta
              name="booklists"
              content={
                "Explore popular booklists from your favourite genres with powerful Machine Learning based recommendations. Be part of the growing booklovers community on Bookclub.ai"
              }
            />
          </Helmet>

          <div style={{ margin: "10px 10px 30px 20px" }}>
            <h4>Browse Popular Lists by Categories on BookClub.ai</h4>
          </div>

          <NavLists setselectedOption={setselectedOption} />
          <ErrorBoundary>
            <Masonry
              breakpointCols={myBreakpointsAndCols}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {items(list_data)}
            </Masonry>
          </ErrorBoundary>
        </div>
      </div>
      <ScrollToTop smooth width={30} color="brown" viewBox="0 0 256 256" />
    </div>
  );
};

export default Lists;
