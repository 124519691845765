import React from "react";
import { useParams } from "react-router";
import { NonDetailPageAPI } from "./MongoDB";
import { ShowAllListSeriesGenre,capitalizeFirstLetter } from "./MasterFile";
import { Helmet } from 'react-helmet-async';
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');


const SeriesPage = () => {
  const params = useParams();

  const series_id = params["id"].toLowerCase()?.replace(/-/g, ' ');
  const input_key = "primary_key";
  /* Detailpage() is a reusable function to get MongoDB show all data for series,list and genre */
  let MongodbResults = NonDetailPageAPI(series_id);

  /* ShowAllListSeriesGenre() is a reusable function to render books in showall page */
  return <>
                <Helmet>
<title>{params["id"]?.replace(/-/g, ' ')}</title>
      <meta name='series' content={"Explore all books in " + capitalizeFirstLetter(params["id"]?.replace(/-/g, ' ')) +" series"} />
      </Helmet>
    
    {ShowAllListSeriesGenre(MongodbResults, series_id)}</>;
};

export default SeriesPage;
