import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";



let TYPESENSE_SERVER_CONFIG = {
  apiKey: "UBEu2Rlx7dRWUbD0kHaoewh0b0alGKvG",
  nodes: [
    {
      host: "g723ywvq0htdfronp-1.a1.typesense.net",
      port: "443",
      protocol: "https",
    },
  ],
  connectionTimeoutSeconds: 1,
  numRetries: 8,
};



export const typesenseAdapter = new TypesenseInstantsearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy: "book_title_goodreads,author,genres,description",
    queryByWeights: "8,4,3,1",
    numTypos: 5,
    typoTokensThreshold: 1,
    per_page: 40,
    facet_by:["author"]
  },
});

export const searchClient = typesenseAdapter.searchClient;

