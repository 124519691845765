import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import Header from "./Header";
import ReactGA from "react-ga";
import { MoreLikeThisPersonalized } from "./MongoDB";
import {
  ErrorBoundary,
  CleanCacheData,
  carousel_parse_all_homepage_personalization,
  carousel_parse_all_homepage_personalization_non_show_all,
  CleanCacheDataPersonalized,
} from "./MasterFile";
import requests from "./request";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import Footer from "./Footer";
import { ListHome, GenresHome } from "./ListHome";
import CelebrityHome from "./CelebrityHome";
import Masthead from "./Masthead";
import axios from "axios";
import Community from "./Community";
import KnowledgeGraphHome from "./KGraphHome";

var individual_json = require("./CacheData/homepage_cache_data_dec25.json");

// carousel_sequence_in_json=
// 'New York Times Bestsellers for Fiction',
//  'Most Addictive PageTurners of All Time',
//  'National Prize Winners for Fiction',
//  "National Prize Winners for Young People's Literature",
//  'National Prize Winners for Non-Fiction',
//  'Most Read Books of All Time',
//  'Booker Prize winners',
//  "Reese's BookClub Picks",
//  'Pulitzer Prize Winners for Fiction',
//  'Pulitzer Prize Winners for Non-Fiction',
//  'New York Times Bestsellers for Book Series'

let window_size = window.innerWidth;
const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

export function Home() {
  const [Embeddings, setEmbeddings] = useState();
  const [books, setbooks] = useState();

  // React.useEffect(() => {

  //   // https://www.freecodecamp.org/news/debouncing-explained/
  //   var data = '{"data":"' + "testing"  + '"}';
  //   var config = {
  //     method: 'post',
  //     url: 'https://skiloseqe3.execute-api.us-east-1.amazonaws.com/Embeddings',
  //     headers: {
  //       'Content-Type': 'text/plain'
  //     },
  //     data : data
  //   };

  //   const getData = setTimeout(() => {
  //     axios(config).then((req) => setEmbeddings(req['data']['embeddings']))
  //     .then(console.log("Model1"))
  //   }, 100)

  //   return () => clearTimeout(getData)
  // }, ["testing"])

  // React.useEffect(() => {

  //     var data2 = '{"embeddings":' + "["+Embeddings + "]"+'}';

  //     var config = {
  //       method: 'post',
  //       url: 'https://r7cjy0l6v9.execute-api.us-east-1.amazonaws.com/Test2KNN/myresourcebookclub',
  //       headers: {
  //         'Content-Type': 'text/plain'
  //       },
  //       data : data2
  //     };

  //     const getData = setTimeout(() => {
  //       axios(config).then((req) => setbooks(req?.data))
  //       .then(console.log("Model2"))

  //     }, 100)

  //   return () => clearTimeout(getData)
  // }, [Embeddings])

  // For Testing
  // console.log(books)

  /* Read from MongoDB */
  // let home_page_results = Homepage_data("Homepage",selectedOption);
  const ref = useRef();

  const [home_data_0, carousel_names_0] = CleanCacheData(individual_json[0]);
  const [home_data_1, carousel_names_1] = CleanCacheData(individual_json[1]);
  const [home_data_2, carousel_names_2] = CleanCacheData(individual_json[2]);
  const [home_data_3, carousel_names_3] = CleanCacheData(individual_json[3]);
  const [home_data_4, carousel_names_4] = CleanCacheData(individual_json[4]);
  const [home_data_5, carousel_names_5] = CleanCacheData(individual_json[5]);
  const [home_data_6, carousel_names_6] = CleanCacheData(individual_json[6]);
  const [home_data_7, carousel_names_7] = CleanCacheData(individual_json[7]);
  const [home_data_8, carousel_names_8] = CleanCacheData(individual_json[8]);
  const [home_data_10, carousel_names_10] = CleanCacheData(individual_json[10]);

  // if (personalized_carousel != undefined) {
  //   const [home_data2, carousel_names2, title] = CleanCacheDataPersonalized(
  //     personalized_carousel
  //   );

  //   personalized_carousel =
  //     carousel_parse_all_homepage_personalization_non_show_all(
  //       home_data2,
  //       carousel_names2,
  //       ref,
  //       "/genres/all/",
  //       title
  //     );
  // }

  // if (browse_carousel != undefined) {
  //   const [browse_data, browse_carousel_names] =
  //     CleanCacheData(browse_carousel);

  //   const browse_data_2 = browse_data.reverse();

  //   function getUniqueListBy(arr, key) {
  //     return [...new Map(arr.map((item) => [item[key], item])).values()];
  //   }

  //   let browse_data_3 = getUniqueListBy(browse_data_2, "seed_title");

  //   browse_carousel = carousel_parse_all_homepage_personalization_non_show_all(
  //     browse_data_3,
  //     browse_carousel_names,
  //     ref,
  //     "/genres/all/"
  //   );
  // }

  let height_hall_of_fame = 630;

  if (window_size < 900) {
    height_hall_of_fame = 340;
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>{"Bookclub.ai| Meet your next book"}</title>
        <meta
          name="homepage"
          content={
            "Explore books from bestsellers, celebrities and book-series with powerful Machine Learning based recommendations. Be part of the growing booklovers community on Bookclub.ai"
          }
        />
      </Helmet>

      <Header />
      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        {/* <NavHome setselectedOption={setselectedOption}/> */}
        <ErrorBoundary>
          {Masthead()}
          {CelebrityHome()}
          {GenresHome()}
          {/* {personalized_carousel}

          {browse_carousel} */}

          {ListHome()}
          {KnowledgeGraphHome()}

          {carousel_parse_all_homepage_personalization(
            home_data_0,
            carousel_names_0,
            ref,
            "/genres/all/"
          )}
          {/* {carousel_parse_all_homepage_personalization(
            home_data_1,
            carousel_names_1,
            ref,
            "/genres/all/"
          )} */}
          {carousel_parse_all_homepage_personalization(
            home_data_7,
            carousel_names_7,
            ref,
            "/genres/all/"
          )}
          {carousel_parse_all_homepage_personalization(
            home_data_5,
            carousel_names_5,
            ref,
            "/genres/all/"
          )}
          {/* DONT DELETE THIS in code cleaning */}
          {/* {carousel_parse_all_homepage_personalization(
            home_data_8,
            carousel_names_8,
            ref,
            "/genres/all/"
          )}

          //  DONT DELETE THIS in code cleaning
{carousel_parse_all_homepage_personalization(
            home_data_4,
            carousel_names_4,
            ref,
            "/genres/all/"
          )} */}
          {/* <iframe
            src="https://flo.uri.sh/visualisation/10871587/embed"
            width="100%"
            height={height_hall_of_fame}
          ></iframe> */}
          {/* DONT DELETE THIS in code cleaning */}
          {/* {carousel_parse_all_homepage_personalization(
            home_data_10,
            carousel_names_10,
            ref,
            "/genres/all/"
          )} */}
          {/* {carousel_parse_all(home_data2, carousel_names2, ref, "/")} */}
        </ErrorBoundary>
      </div>
      <ScrollToTop smooth width={30} color="brown" viewBox="0 0 256 256" />
      <Footer />
    </div>
  );
}

export default Home;
