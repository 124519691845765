import React, { useState } from "react";
import "./Nav.css";
import requests from "./request";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBCollapse,
} from "mdb-react-ui-kit";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');



export function NavCelebrity({ setselectedOption }) {
  return (
    <ul class="nav justify-content-left">
      <MDBNavbar expand="sm" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarNav
            fullWidth={false}
            className="d-flex flex-row bd-highlight mb-1"
          >
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchMusicians)}
              >
                Musicians
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchEntrepreneurs)}
              >
                {" "}
                Entrepreneurs
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchInvestor)}
              >
                {" "}
                Investors
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchAuthors)}
              >
                {" "}
                Authors
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchActivist)}
              >
                {" "}
                Activists
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchFilmmakers)}
              >
                {" "}
                Filmmakers
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchAthletes)}
              >
                {" "}
                Athletes
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchPoliticians)}
              >
                {" "}
                Politicians
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchChefs)}
              >
                {" "}
                Chefs
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchModels)}
              >
                {" "}
                Models
              </MDBNavbarLink>
            </MDBNavbarItem>


          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </ul>
  );
}

// NavBar on Home Page with clickability and Bootstrap
export function NavHome({ setselectedOption }) {
  return (
    <ul class="nav justify-content-center">
      <MDBNavbar expand="sm" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarNav
            fullWidth={false}
            className="d-flex flex-row bd-highlight mb-1"
          >
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchFiction)}
              >
                {" "}
                Fiction
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchNonFiction)}
              >
                {" "}
                Non-Fiction
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchYoungAdult)}
              >
                {" "}
                Young-Adult
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </ul>
  );
}

// NavBar on Genre Page with clickability and Bootstrap

export function NavGenre({ setselectedOption }) {
  return (
    <ul class="nav justify-content-center">
      <MDBNavbar expand="sm" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarNav
            fullWidth={false}
            className="d-flex flex-row bd-highlight mb-1"
          >
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchRomance)}
              >
                {" "}
                Romance
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fantasy)}
              >
                {" "}
                Fantasy
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchScienceFiction)}
              >
                {" "}
                ScienceFiction
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchCrime)}
              >
                {" "}
                Crime
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchHistorical)}
              >
                {" "}
                Historical
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchFamily)}
              >
                {" "}
                Family
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchYoungAdult)}
              >
                {" "}
                YA
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchFiction)}
              >
                {" "}
                Fiction
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchTechnology)}
              >
                {" "}
                Technology
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchChildrens)}
              >
                Children
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchSuspense)}
              >
                Suspense
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchBusiness)}
              >
                Business
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchBiography)}
              >
                Biography
              </MDBNavbarLink>
            </MDBNavbarItem>
            {/* <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchHealth)}
              >
                Health
              </MDBNavbarLink>
            </MDBNavbarItem> */}
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchChildrens)}
              >
                Children
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchNonFiction)}
              >
                NonFiction
              </MDBNavbarLink>
            </MDBNavbarItem>
            {/* <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchSelfHelp)}
              >
                SelfHelp
              </MDBNavbarLink>
            </MDBNavbarItem> */}
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.Religion)}
              >
                Religion
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchShortStories)}
              >
                ShortStories
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchLGBT)}
              >
                LGBT
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </ul>
  );
}

export function NavSeries({ setselectedOption }) {
  const [showNavCentred, setShowNavCentred] = useState(false);
  return (
    <ul class="nav justify-content-left">
      <MDBNavbar expand="sm" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarNav
            fullWidth={false}
            className="d-flex flex-row bd-highlight mb-1"
          >
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchScienceFictionSeries)}
              >
                {" "}
                Sci-Fi
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchCrimeSeries)}
              >
                Crime
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchYASeries)}
              >
                {" "}
                Young Adult
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchRomanceSeries)}
              >
                {" "}
                Romance
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchHistoricalSeries)}
              >
                {" "}
                History
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchFictionSeries)}
              >
                {" "}
                Fiction
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchBiographySeries)}
              >
                {" "}
                Biography
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchNFSeries)}
              >
                {" "}
                Non-Fiction
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchFantasySeries)}
              >
                {" "}
                Fantasy
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
          {/* </MDBCollapse> */}
        </MDBContainer>
      </MDBNavbar>
    </ul>
  );
}

export default function NavLists({ setselectedOption }) {
  const [showNavCentred, setShowNavCentred] = useState(false);
  return (
    <ul class="nav justify-content-left">
      <MDBNavbar expand="sm" light bgColor="light">
        <MDBContainer fluid>
          <MDBNavbarNav
            fullWidth={false}
            className="d-flex flex-row bd-highlight mb-1"
          >
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchRomance)}
              >
                {" "}
                Romance
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchHobbies)}
              >
                Hobby
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchCultural)}
              >
                {" "}
                Culture
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchTechnologyList)}
              >
                {" "}
                Tech
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchHistory)}
              >
                {" "}
                History
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchManga)}
              >
                {" "}
                Manga
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchBusinessList)}
              >
                {" "}
                Business
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchHealth)}
              >
                {" "}
                Health
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="p-1 flex-fill bd-highlight">
              <MDBNavbarLink
                href="#"
                onClick={() => setselectedOption(requests.fetchFantasyList)}
              >
                {" "}
                Fantasy
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
          {/* </MDBCollapse> */}
        </MDBContainer>
      </MDBNavbar>
    </ul>
  );
}
