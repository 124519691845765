import React, { useState, useRef } from "react";
import Header from "./Header";
import { NavGenre } from "./Nav";
import requests from "./request";
import {
  ErrorBoundary,
  carousel_parse_all,
  CleanMongoDBDataAPI
} from "./MasterFile";
import {NonDetailPageAPI } from "./MongoDB";
import { Helmet } from 'react-helmet-async';
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');



let window_size=window.innerWidth
const GenrePage = () => {
  const [selectedOption, setselectedOption] = useState(
    requests.fetchFantasy
  );
  const ref = useRef();
  
  // let genre_page_results = GenreMainPage(selectedOption);

  let genre_page_results = NonDetailPageAPI(selectedOption);


  const [genre_data, carousel_names] = CleanMongoDBDataAPI(genre_page_results)
  

  let header_font_size='2.5vw'
  if(window_size<800){
  
    header_font_size='4vw'
  }

  return (
    <div>
<Helmet>
<title>{"Bookclub.ai| Find your next book from your favourite Genre"  }</title>
      <meta name='genres' content={"Explore books from your favourite Genre with powerful Machine Learning based recommendations. Be part of the growing booklovers community on Bookclub.ai"} />
</Helmet>
      <Header />
      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        <div className="detail__cast">
          
        
      <div style={{ margin: '10px 10px 20px 20px' }}>
            <h1 style={{ fontSize:header_font_size }}>Select your favorite genre to start exploring.</h1>
            </div>

        <h4 onClick={() => setselectedOption(requests.fetchFantasy)}></h4>

        <NavGenre setselectedOption={setselectedOption} />
        <ErrorBoundary>
          {/* carousel_parse_all() function is a reusable component powering carousel name and underlying data */}
          {carousel_parse_all(genre_data, carousel_names, ref, "/genres/all/")}
        </ErrorBoundary>
      </div>
      </div>
      <ScrollToTop smooth width={30} color="brown"  viewBox="0 0 256 256"/>
      </div>
  );
};

export default GenrePage;
