import React from "react";
import { connectHits } from "react-instantsearch-core";

import ReactCardFlipper from "react-card-flipper";
import Masonry from "react-masonry-css";
import "./search_main.scss"
import Rating from "react-rating";
import { Link } from "react-router-dom";

const sizeOf = require('image-size')

let empty_star_flippy = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="black"
    class="bi bi-star"
    viewBox="0 0 16 16"
  >
    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
  </svg>
);
let complete_star_flippy = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="black"
    class="bi bi-star-fill"
    viewBox="0 0 16 16"
  >
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
  </svg>
);

const myBreakpointsAndCols = {
  default: 5,
  1100: 5,
  700: 3,
  500: 2,
  300: 2,
};

const truncate = (text, n) =>
  text?.length > n ? text.substr(0, n - 1) + "..." : text;

/* Make first letter as Capital */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const carousel_books = (books) => {
  let td = [];
  const description = capitalizeFirstLetter(
    truncate(books?.description, 200)
  );
  
// const dimensions = sizeOf("https://ik.imagekit.io/bookclub/" +books["image_name2"]+".jpg")
//   console.log(books["book_title_goodreads"], dimensions.width, dimensions.height)
  
  
  let image = "https://ik.imagekit.io/bookclub/" + books["image_name2"] + ".jpg?tr=w-300,h-500"

  
  if (!image.includes('34003072'))
  {
  td.push(
   
      
    <div className="FlippyCardSearch">
         <Link to={"/book/" + books["image_name2"]} target="_blank">
    
        <ReactCardFlipper
          width="100%"
          height="300px"
          behavior="hover"
          levitate={false}
        >
            <div>
            <img
               src={
                "https://ik.imagekit.io/bookclub/tr:di-sample_image_2.png/" +
                books["image_name2"] +
                ".jpg?tr=w-300,h-500"
              }
              width="180px"
              height="300px"
              alt={books["book_title_goodreads"]}
            ></img>
          </div>

          <div>
            <h3 class="card-flip-book-name">{books["book_title_goodreads"]}</h3>
            <h6 class="card-flip-author-name">{"by " + books?.author}</h6>
            <span class="card-flip-author-name">
              {" "}
              {
                <Rating
                  placeholderRating={Number(books?.average_rating).toFixed(2)}
                  step={1}
                  fractions={10}
                  readonly
                  placeholderSymbol={complete_star_flippy}
                  emptySymbol={empty_star_flippy}
                />
              }{" "}
              {" (" + (books.num_ratings / 1000).toFixed() + "k)"}
            </span>

            {/* <div class="nav_all">{"Genres"} </div>  */}
            <br></br><p></p>
            <h6 class="card-flip-description">
              {truncate(books["description"], 400)}
            </h6>
            {/* <MDBBtn class="btn btn-success" href={"/book/" + books["image_name2"]}>
                  Detail Page
                </MDBBtn> */}

            {/* <MDBBtn href='#'><i class={buy_icon}></i></MDBBtn> */}
          </div>
          
        </ReactCardFlipper>
        </Link>
      
    </div>
  )};

  return td;
};


function MoviesHits({ hits }) {
  return (
    <>
                  <Masonry
              breakpointCols={myBreakpointsAndCols}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
        {hits.map((hit) => carousel_books(hit))}
        </Masonry>
    </>
  );
}

export default connectHits(MoviesHits);
