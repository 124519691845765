import React from "react";
import { useParams } from "react-router";
import { ShowAllListSeriesGenre } from "./MasterFile";
import { NonDetailPageAPI } from "./MongoDB";
import { CleanMongoDBData,capitalizeFirstLetter } from "./MasterFile";
import { ErrorBoundary } from "./MasterFile";
import "./Nav.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Helmet } from 'react-helmet-async'; 
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');


const CelebrityDetailPage = () => {
  const params = useParams();

  const genre_id = params["id"].toLowerCase()?.replace(/-/g, ' ');
  let input_key = "primary_key";
  let MongodbResults = NonDetailPageAPI(genre_id?.toLowerCase());
  

  /* ShowAllListSeriesGenre() is a reusable function to render books in showall page */
  return <>
        <Helmet>
<title>{params["id"]?.replace(/-/g, ' ')}</title>
      <meta name='description' content={"Explore all books by " + capitalizeFirstLetter(params["id"]?.replace(/-/g, ' '))} />
      </Helmet>
    <ErrorBoundary>
      {ShowAllListSeriesGenre(MongodbResults, genre_id)}
    </ErrorBoundary>
    <ScrollToTop smooth width={30} color="brown"  viewBox="0 0 256 256"/>
  </>;
};

export default CelebrityDetailPage;
