import React, { useRef, useState,useEffect, Component } from "react";
import Header from "./Header";
import Card from "react-bootstrap/Card";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { motion } from "framer-motion";
import "./community.scss";
import { DetailPageAPI } from "./MongoDB";
import {

  what_is_this_book,truncate,CleanCacheDataModal
} from "./MasterFile";

import { VscChromeClose } from "react-icons/vsc";
// const TRACKING_ID = "G-X2EQPBPN7L";
// ReactGA.initialize("G-X2EQPBPN7L");
import { useParams } from "react-router";
// var AWS = require('aws-sdk/dist/aws-sdk-react-native');
import axios from "axios";
import Highcharts from 'highcharts'
import networkgraph from "highcharts/modules/networkgraph";
import ReactGA from "react-ga";


const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');





networkgraph(Highcharts);

export const defaultEasing = [0.6, -0.05, 0.01, 0.99];

export const staggerOne = {
  animate: { transition: { staggerChildren: 0.1 } },
};
let window_size=window.innerWidth
export const modalFadeInUpVariants = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

export function ModalData(props,book_name,description,personalized_carousel,author_name,average_rating,num_ratings,year_first_published,genres) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    
     
      <Modal.Body scrollable="true">
        <motion.div
          variants={staggerOne}
          initial="initial"
          animate="animate"
          exit="exit"
          className="Modal__info--wrp"
        >
          	<motion.button
								className="Modal__closebtn"
								onClick={props.onHide}
							>
								<VscChromeClose />
							</motion.button>

          <motion.h3
            variants={modalFadeInUpVariants}
            className="Modal__info--title"
          >
            {book_name}
          </motion.h3>
          <motion.p
            variants={modalFadeInUpVariants}
            className="Modal__info--description"
          >
            {description}
          </motion.p>
          <motion.hr
            variants={modalFadeInUpVariants}
            className="Modal__info--line"
          />
          {personalized_carousel}
          <motion.hr
            variants={modalFadeInUpVariants}
            className="Modal__info--line"
          />
          <motion.h4
            variants={modalFadeInUpVariants}
            className="Modal__info--otherTitle"
          >
            Info on <b>{book_name}</b>
          </motion.h4>
          <motion.div
            variants={modalFadeInUpVariants}
            className="Modal__info--row"
          >
            <span className="Modal__info--row-label">Author: </span>
            <span className="Modal__info--row-description">
              {author_name}
            </span>
          </motion.div>
          <motion.div
            variants={modalFadeInUpVariants}
            className="Modal__info--row"
          >
            <span className="Modal__info--row-label">Avg. Rating: </span>
            <span className="Modal__info--row-description">
              {average_rating}
            </span>
          </motion.div>
          <motion.div
            variants={modalFadeInUpVariants}
            className="Modal__info--row"
          >
            <span className="Modal__info--row-label">Number Of Ratings: </span>
            <span className="Modal__info--row-description">
              {num_ratings}
            </span>
          </motion.div>


                    <motion.div
            variants={modalFadeInUpVariants}
            className="Modal__info--row"
          >
            <span className="Modal__info--row-label">Year First Published: </span>
            <span className="Modal__info--row-description">
              {year_first_published}
            </span>
          </motion.div>


          <motion.div
            variants={modalFadeInUpVariants}
            className="Modal__info--row"
          >
            <span className="Modal__info--row-label">Genres: </span>
            <span className="Modal__info--row-description">
              {genres}
            </span>
          </motion.div>


        </motion.div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
} 


export function MyVerticallyCenteredModal0(props) {
  


  const ref = useRef();



  let book_name = props.dynData0.title

  let author_name = props.dynData0.id?.['author'];
  let description =props.dynData0.description
  let personalized_carousel = props.dynData0.id
  let average_rating=props.dynData0.id?.average_rating
  let num_ratings=props.dynData0.id?.num_ratings
  let year_first_published=props.dynData0.id?.year_first_published
  let genres=props.dynData0.id?.genres?.split(",")
  
  
  if (personalized_carousel != undefined) {
    const [home_data2, carousel_names2, title] = CleanCacheDataModal(
      personalized_carousel
    );
   
  
    personalized_carousel = what_is_this_book(
      home_data2,
      ref,
    );
  }
  return (ModalData(props,book_name,description,personalized_carousel,author_name,average_rating,num_ratings,year_first_published,genres))
}

export function MyVerticallyCenteredModal1(props) {



  const ref = useRef();


  let book_name = props.dynData1.title
  let author_name = props.dynData1.id?.['author'];
  let description =props.dynData1.description
  let personalized_carousel = props.dynData1.id
  let average_rating=props.dynData1.id?.average_rating
  let num_ratings=props.dynData1.id?.num_ratings
  let year_first_published=props.dynData1.id?.year_first_published
  let genres=props.dynData1.id?.genres?.split(",")
  
  if (personalized_carousel != undefined) {
    const [home_data2, carousel_names2, title] = CleanCacheDataModal(
      personalized_carousel
    );
   
  
    personalized_carousel = what_is_this_book(
      home_data2,
      ref,
    );
  }
  
  return (ModalData(props,book_name,description,personalized_carousel,author_name,average_rating,num_ratings,year_first_published,genres))
}



export function MyVerticallyCenteredModal2(props) {
  



  const ref = useRef();

  let book_name = props.dynData2.title


  let author_name = props.dynData2.id?.['author'];
  let description =props.dynData2.description
  let personalized_carousel = props.dynData2.id
  let average_rating=props.dynData2.id?.average_rating
  let num_ratings=props.dynData2.id?.num_ratings
  let year_first_published=props.dynData2.id?.year_first_published
  let genres=props.dynData2.id?.genres?.split(",")
  

  if (personalized_carousel != undefined) {
    const [home_data2, carousel_names2, title] = CleanCacheDataModal(
      personalized_carousel
    );
   
  
    personalized_carousel = what_is_this_book(
      home_data2,
      ref,
    );
  }

  return (ModalData(props,book_name,description,personalized_carousel,author_name,average_rating,num_ratings,year_first_published,genres))

}

export function MyVerticallyCenteredModal3(props) {
  
  const ref = useRef();



  let book_name = props.dynData3.title
  let author_name = props.dynData3.id?.['author'];
  let description =props.dynData3.description
  let personalized_carousel = props.dynData3.id
  let average_rating=props.dynData3.id?.average_rating
  let num_ratings=props.dynData3.id?.num_ratings
  let year_first_published=props.dynData3.id?.year_first_published
  let genres=props.dynData3.id?.genres?.split(",")
  
  if (personalized_carousel != undefined) {
    const [home_data2, carousel_names2, title] = CleanCacheDataModal(
      personalized_carousel
    );
   
  
    personalized_carousel = what_is_this_book(
      home_data2,
      ref,
    );
  }

  return (ModalData(props,book_name,description,personalized_carousel,author_name,average_rating,num_ratings,year_first_published,genres))
}








export const AnimatedInput = ({placeholder: passedPlaceholder = "", ...passedProps}) => {
  const [random_number, setrandom_number] = useState(0);
  const [placeholder, setPlaceholder] = useState(passedPlaceholder[random_number].slice(0, 0));
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  
  
  useEffect(() => {
      const intr = setInterval(() => {
          setPlaceholder(passedPlaceholder[random_number].slice(0, placeholderIndex));
          if (placeholderIndex + 1 > passedPlaceholder[random_number].length) {
              setPlaceholderIndex(0)
              setrandom_number(Math.floor(Math.random() * 3))
              
          } else {
              setPlaceholderIndex(placeholderIndex + 1)
              
            
              
          }
      }, 150);
      return () => {
          clearInterval(intr)
      }
  },);


  return <input {...passedProps} placeholder={placeholder}/>
};



export function CommunityDefault(input) {


 

  const params = useParams();

  return (params['id']=="Time Travel Romance" ? CommunityInitialize():Community())
  
  }


  function CommunityInitialize() {
    const [text, setText] = useState("Time Travel Romance"); 

    // const [pinCode, setPinCode] = React.useState("Time Travel Romance");
    const [submitted, setSubmitted] = useState("Time Travel Romance");
    const [Embeddings, setEmbeddings] = useState();
    const [books, setbooks] = useState();
  
    let sample_books = [
      "18619684",
      "40526",
      "882028",
      "41035725",
      "77773"]
  
      
  if (books != undefined) {
    for (var i = 4; i >=0; i--) {
  
    
      let book_info = books?.[i]['image_name2'].toString()
    
        { sample_books.push(book_info) }
  
        
      }
      sample_books = sample_books.reverse();
    }
  
  
  
  
  
  
    const [modalShow0, setModalShow0] = React.useState(false);
    const [modalShow1, setModalShow1] = React.useState(false);
    const [modalShow2, setModalShow2] = React.useState(false);
    const [modalShow3, setModalShow3] = React.useState(false);
    
  
  
    let test_book2=[]
    for (var i = 0; i < 5; i++) {
    

      let book_info_data = DetailPageAPI(sample_books[i])
   
     
      if (book_info_data?.length== 0 )
      { continue; }
        
      test_book2.push(book_info_data)

   
     
    }
  
  
  
  
    
  
    const modalData0 = {
      title: test_book2[0]?.book_title_goodreads ,
      description: test_book2[0]?.description ,
      id: test_book2[0]
    };

  
    const modalData1 = {
      title: test_book2[1]?.book_title_goodreads ,
      description: test_book2[1]?.description ,
      id: test_book2[1]
    };
  
    const modalData2 = {
      title: test_book2[2]?.book_title_goodreads ,
      description: test_book2[2]?.description ,
      id: test_book2[2]
    };
  
    const modalData3 = {
      title: test_book2[3]?.book_title_goodreads ,
      description: test_book2[3]?.description ,
      id: test_book2[3]
    };
  
   
    let td = [];
    td.push(
      <>
  
      <div className="d-flex justify-content-around">
        <div className="book_genie_2">
          <Card style={{ width: "18rem" }}>
            <Card.Img
              variant="top"
              style={{ width: 275, height: 250 }}
              src={
                "https://ik.imagekit.io/bookclub/" +
                test_book2[0]?.seed_title +
                ".jpg?tr=w-300,h-500"
              }
            />
            <Card.Body className="book_genie_1">
            <div className="book_genie_title">
                <Card.Title style={{height: '70px', color: 'black'}} > {truncate(test_book2[0]?.book_title_goodreads, 50)}</Card.Title>
                </div>
              {/* <Card.Text>
                {truncate(test_book2[0]?.[0]?.description, 100)}
                
              </Card.Text> */}
              <Button variant="warning" onClick={() => setModalShow0(true)} >
                  
                  Book Info
                  </Button>
                  
                 
                  <MyVerticallyCenteredModal0
                      dynData0={modalData0}
                    show={modalShow0}
                    onHide={() => setModalShow0(false)}
             
    
                />
            </Card.Body>
          </Card>
        </div>
  
        <div className="book_genie_2">
          <Card style={{ width: "18rem" }}>
            <Card.Img
              variant="top"
              style={{ width: 275, height: 250 }}
              src={
                "https://ik.imagekit.io/bookclub/" +
                test_book2[1]?.seed_title +
                ".jpg?tr=w-300,h-500"
              }
            />
            <Card.Body className="book_genie_1">
            <div className="book_genie_title">
                <Card.Title style={{height: '70px', color: 'black'}}> {truncate(test_book2[1]?.book_title_goodreads, 50)}</Card.Title>
                </div>
              {/* <Card.Text>
                {truncate(test_book2[1]?.[0]?.description, 100)}
                
              </Card.Text> */}
              {/* <Button variant="primary">Go somewhere</Button> */}
              <Button variant="warning" onClick={() => setModalShow1(true)} dynData1={modalData1}>
                  
                  Book Info
                  </Button>
                  
                  
    
                 
                  <MyVerticallyCenteredModal1
                      dynData1={modalData1}
                    show={modalShow1}
                    onHide={() => setModalShow1(false)}
             
    
                />
              
                
  
            </Card.Body>
          </Card>
        </div>
  
        <div className="book_genie_2">
          <Card style={{ width: "18rem" }}>
            <Card.Img
              variant="top"
              style={{ width: 275, height: 250 }}
              src={
                "https://ik.imagekit.io/bookclub/" +
                test_book2[2]?.seed_title +
                ".jpg?tr=w-300,h-500"
              }
            />
            <Card.Body className="book_genie_1">
            <div className="book_genie_title">
                <Card.Title style={{height: '70px', color: 'black'}}> {truncate(test_book2[2]?.book_title_goodreads, 50)}</Card.Title>
                </div>
              {/* <Card.Text>
                {truncate(test_book2[2]?.[0]?.description, 100)}
                
              </Card.Text> */}
              {/* <Button variant="primary">Go somewhere</Button> */}
              <Button variant="warning" onClick={() => setModalShow2(true)} >
                  
                  Book Info
                  </Button>
                  
                  
    
                 
                  <MyVerticallyCenteredModal2
                      dynData2={modalData2}
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
             
    
                />
            </Card.Body>
          </Card>
        </div>
  
        <div className="book_genie_2">
          <Card style={{ width: "18rem" }}>
            <Card.Img
              variant="top"
              style={{ width: 275, height: 250 }}
              src={
                "https://ik.imagekit.io/bookclub/" +
                test_book2[3]?.seed_title +
                ".jpg?tr=w-300,h-500"
              }
            />
            <Card.Body className="book_genie_1">
            <div className="book_genie_title">
                <Card.Title style={{height: '70px', color: 'black', textSizeAdjust: 'small'}}> {truncate(test_book2[3]?.book_title_goodreads, 50)}</Card.Title>
                </div>
              {/* <Card.Text>
                {truncate(test_book2[3]?.[0]?.description, 100)}
                
              </Card.Text> */}
              {/* <Button variant="primary">Go somewhere</Button> */}
              <Button variant="warning" onClick={() => setModalShow3(true)} >
                  
                  Book Info
                  </Button>
                  
                  
    
                 
                  <MyVerticallyCenteredModal3
                      dynData3={modalData3}
                    show={modalShow3}
                    onHide={() => setModalShow3(false)}
             
    
                />
            </Card.Body>
          </Card>
        </div>
      </div>
        
      </>)
    
  
    
      
  
      const handleChange = (event) => {
        // https://stackoverflow.com/questions/31272207/to-call-onchange-event-after-pressing-enter-key
        // Update the text in the input with the user's input
        setText(() => event.target.value);
        
    
      };
    
      const handleClick = (event) => {
          // event.preventDefault();
          setSubmitted(text);
          window.open(text, "_self")
  
      }
    
      const handleKeypress = e => {
    
      if (e.key === 'Enter') {
        handleClick()
      }
    };
  
  
    let list_place="Ask something like - Vampire Romance "
    let return_query="Time Travel Romance Books"
    if(submitted.length>0)
    {return_query=text}
  
    const [placeholder, setPlaceholder] = useState('Search...');
    const options = ["Women Lead in World War","Top Cryptocurrency books","Time Travel Romance Books","Fantasy Fiction with Magic","Inspiring books with Aliens"]
  
    function handleChangePlaceholder1() {
      setPlaceholder(options[0]);
      setText(options[0])
      console.log(text)
      window.open(options[0], "_self")
    }
    function handleChangePlaceholder2() {
      setPlaceholder(options[1]);
      setText(options[1])
      window.open(options[1], "_self")

    }
    function handleChangePlaceholder3() {
      setPlaceholder(options[2]);
      setText(options[2])
      window.open(options[2], "_self")
    }
    function handleChangePlaceholder4() {
      setPlaceholder(options[3]);
      setText(options[3])
      window.open(options[3], "_self")
    }
  
    function handleChangePlaceholder5() {
      setPlaceholder(options[4]);
      setText(options[4])
      window.open(options[4], "_self")
    }
  
    let recent_searches = [];
   let header_font_size='2.5vw'
   let header_font_size2='1.2vw'
    if (window_size < 800) {
     header_font_size='5vw'
     header_font_size2='2.5vw'
      recent_searches.push(      
      <span> <p class="LazyPeopleSearch1">{" "}
      <span class="LazyPeopleSearch2">Try:{" "} </span>
        <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder1}>  {" " + options[0]} </button>  
        <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder2}>  {" " + options[1]} </button>  
  
        
       </p>
       
      </span>)
  
  
    }
    else
    {
      recent_searches.push(
        <span> <p class="LazyPeopleSearch1">{" "}
        <span class="LazyPeopleSearch2">Top Searches:{" "} </span>
          <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder1}>  {" " + options[0]} </button>  
          <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder2}>  {" " + options[1]} </button>  
          <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder3}>  {" " + options[2]} </button>  
          <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder4}>  {" " + options[3]} </button>
          <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder5}>  {" " + options[4]} </button>
  
          
         </p>
         
        </span>
      ) 
    }
  
    
      return (
        <div>
          <Header />
          {/* {personalized_carousel} */}
  
          
          <div className="text_box">
  
            <h2 style={{ margin: '-20px -20px 20px', fontSize:header_font_size   }}>Introducing Book-Genie: Your Personal AI Book Recommendation Assistant.</h2>
            <p style={{  margin: '-12px 2px 2px',fontSize:header_font_size2   }}>Please<span class="BookGenieResultsBanner"> Refresh </span> the page or <span class="BookGenieResultsBanner"> retype </span>  if recommendations doesn't load, I cannot afford a dedicated server right now as I am in bootstrapped phase.</p>
        <div class="input-group mb-3">
        <input type="text"
            class="form-control"
            // placeholder="Ask anything from BookGenie"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
                style={{ width: 675, height: 50 }}
                
                onChange={handleChange}
  
               
                placeholder={list_place} 
            onKeyPress={handleKeypress}
            />
          
          <div class="input-group-append">
                <Button type="primary" onClick={handleClick}>
          Search here
            </Button>
            </div>
        </div>
        </div>
       
  {recent_searches}
  
          
  
        <span> <p class="BookGenieResultsTitle1">{" "}
          <span class="BookGenieResultsTitle2">Results for:{" "} </span>
          {" "}{" " + return_query}</p></span>
  
        
       {td}
  
  
      
        </div>
      );
    }

    
function Community() {

  const params = useParams();

  const [text, setText] = useState(params["id"]); 

  // const [pinCode, setPinCode] = React.useState("Time Travel Romance");
  const [submitted, setSubmitted] = useState(params["id"]);
  const [Embeddings, setEmbeddings] = useState();
  const [books, setbooks] = useState();

  React.useEffect(() => {

    // https://www.freecodecamp.org/news/debouncing-explained/
    var data = '{"data":"' + text  + '"}';
    var config = {
      method: 'post',
      url: 'https://skiloseqe3.execute-api.us-east-1.amazonaws.com/Embeddings',
      headers: { 
        'Content-Type': 'text/plain'
      },
      data : data
    };

    const getData = setTimeout(() => {
      axios(config).then((req) => setEmbeddings(req['data']['embeddings'])) 
      .then(console.log("Model1"))
    }, 1000)

    return () => clearTimeout(getData)
  }, [text])


  React.useEffect(() => {

      var data2 = '{"embeddings":' + "["+Embeddings + "]"+'}';
  
      var config = {
        method: 'post',
        url: 'https://r7cjy0l6v9.execute-api.us-east-1.amazonaws.com/Test2KNN/myresourcebookclub',
        headers: { 
          'Content-Type': 'text/plain'
        },
        data : data2
      };
      
      const getData = setTimeout(() => {
        axios(config).then((req) => setbooks(req?.data))
        .then(console.log("Model2"))
      
        
      }, 1100)

    return () => clearTimeout(getData)
  }, [Embeddings])
  

  
  let sample_books = []

    
if (books != undefined) {
  for (var i = 4; i >=0; i--) {

  
    let book_info = books?.[i]['image_name2'].toString()
  
      { sample_books.push(book_info) }

      
    }
    sample_books = sample_books.reverse();
  }






  const [modalShow0, setModalShow0] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  


  let test_book2=[]
  for (var i = 0; i < 5; i++) {
    let book_info_data = DetailPageAPI(sample_books[i])
   
    if (book_info_data?.length== 0 )
    { continue; }
      
    test_book2.push(book_info_data)
    // console.log(test_book2?.filter(value => Object.keys(value).length !== 0))
  }

  // var newArray = test_book2?.filter(value => Object.keys(value).length !== 0);


  

  const modalData0 = {
    title: test_book2[0]?.book_title_goodreads ,
    description: test_book2[0]?.description ,
    id: test_book2[0]
  };

  const modalData1 = {
    title: test_book2[1]?.book_title_goodreads ,
    description: test_book2[1]?.description,
    // id: sample_books[1]
    id: test_book2[1]
  };

  const modalData2 = {
    title: test_book2[2]?.book_title_goodreads ,
    description: test_book2[2]?.description,
    id: test_book2[2]
  };

  const modalData3 = {
    title: test_book2[3]?.book_title_goodreads ,
    description: test_book2[3]?.description,
    id: test_book2[3]
  };

 
  let td = [];
  td.push(
    <>

    <div className="d-flex justify-content-around">
      <div className="book_genie_2">
        <Card style={{ width: "18rem" }}>
          <Card.Img
            variant="top"
            style={{ width: 275, height: 250 }}
            src={
              "https://ik.imagekit.io/bookclub/" +
              test_book2[0]?.seed_title +
              ".jpg?tr=w-300,h-500"
            }
          />
          <Card.Body className="book_genie_1">
          <div className="book_genie_title">
              <Card.Title style={{height: '70px', color: 'black'}} > {truncate(test_book2[0]?.book_title_goodreads, 50)}</Card.Title>
              </div>
            {/* <Card.Text>
              {truncate(test_book2[0]?.[0]?.description, 100)}
              
            </Card.Text> */}
            <Button variant="warning" onClick={() => setModalShow0(true)} >
                
                Book Info
                </Button>
                
               
                <MyVerticallyCenteredModal0
                    dynData0={modalData0}
                  show={modalShow0}
                  onHide={() => setModalShow0(false)}
           
  
              />
          </Card.Body>
        </Card>
      </div>

      <div className="book_genie_2">
        <Card style={{ width: "18rem" }}>
          <Card.Img
            variant="top"
            style={{ width: 275, height: 250 }}
            src={
              "https://ik.imagekit.io/bookclub/" +
              test_book2[1]?.seed_title +
              ".jpg?tr=w-300,h-500"
            }
          />
          <Card.Body className="book_genie_1">
          <div className="book_genie_title">
              <Card.Title style={{height: '70px', color: 'black'}}> {truncate(test_book2[1]?.book_title_goodreads, 50)}</Card.Title>
              </div>
            {/* <Card.Text>
              {truncate(test_book2[1]?.[0]?.description, 100)}
              
            </Card.Text> */}
            {/* <Button variant="primary">Go somewhere</Button> */}
            <Button variant="warning" onClick={() => setModalShow1(true)} dynData1={modalData1}>
                
                Book Info
                </Button>
                
                
  
               
                <MyVerticallyCenteredModal1
                    dynData1={modalData1}
                  show={modalShow1}
                  onHide={() => setModalShow1(false)}
           
  
              />
            
              

          </Card.Body>
        </Card>
      </div>

      <div className="book_genie_2">
        <Card style={{ width: "18rem" }}>
          <Card.Img
            variant="top"
            style={{ width: 275, height: 250 }}
            src={
              "https://ik.imagekit.io/bookclub/" +
              test_book2[2]?.seed_title +
              ".jpg?tr=w-300,h-500"
            }
          />
          <Card.Body className="book_genie_1">
          <div className="book_genie_title">
              <Card.Title style={{height: '70px', color: 'black'}}> {truncate(test_book2[2]?.book_title_goodreads, 50)}</Card.Title>
              </div>
            {/* <Card.Text>
              {truncate(test_book2[2]?.[0]?.description, 100)}
              
            </Card.Text> */}
            {/* <Button variant="primary">Go somewhere</Button> */}
            <Button variant="warning" onClick={() => setModalShow2(true)} >
                
                Book Info
                </Button>
                
                
  
               
                <MyVerticallyCenteredModal2
                    dynData2={modalData2}
                  show={modalShow2}
                  onHide={() => setModalShow2(false)}
           
  
              />
          </Card.Body>
        </Card>
      </div>

      <div className="book_genie_2">
        <Card style={{ width: "18rem" }}>
          <Card.Img
            variant="top"
            style={{ width: 275, height: 250 }}
            src={
              "https://ik.imagekit.io/bookclub/" +
              test_book2[3]?.seed_title +
              ".jpg?tr=w-300,h-500"
            }
          />
          <Card.Body className="book_genie_1">
          <div className="book_genie_title">
              <Card.Title style={{height: '70px', color: 'black', textSizeAdjust: 'small'}}> {truncate(test_book2[3]?.book_title_goodreads, 50)}</Card.Title>
              </div>
            {/* <Card.Text>
              {truncate(test_book2[3]?.[0]?.description, 100)}
              
            </Card.Text> */}
            {/* <Button variant="primary">Go somewhere</Button> */}
            <Button variant="warning" onClick={() => setModalShow3(true)} >
                
                Book Info
                </Button>
                
                
  
               
                <MyVerticallyCenteredModal3
                    dynData3={modalData3}
                  show={modalShow3}
                  onHide={() => setModalShow3(false)}
           
  
              />
          </Card.Body>
        </Card>
      </div>
    </div>
      
    </>)
  

  
    

    const handleChange = (event) => {
      // https://stackoverflow.com/questions/31272207/to-call-onchange-event-after-pressing-enter-key
      // Update the text in the input with the user's input
      setText(() => event.target.value);
     
      
      
  
    };
  
    const handleClick = (event) => {
        // event.preventDefault();
        setSubmitted(text);
        

    }
  
    const handleKeypress = e => {
  
    if (e.key === 'Enter') {
      handleClick()
      
    }
  };


  let list_place="Ask something like - Vampire Romance "
  let return_query="Time Travel Romance Books"
  if(submitted.length>0)
  {return_query=text}

  const [placeholder, setPlaceholder] = useState('Search...');
  const options = ["Women Lead in World War","Top Cryptocurrency books","Time Travel Romance Books","Fantasy Fiction with Magic","Inspiring books with Aliens"]

  function handleChangePlaceholder1() {
    setPlaceholder(options[0]);
    setText(options[0])
    window.open(options[0], "_self")
  }
  function handleChangePlaceholder2() {
    setPlaceholder(options[1]);
    setText(options[1])
    window.open(options[1], "_self")
  }
  function handleChangePlaceholder3() {
    setPlaceholder(options[2]);
    setText(options[2])
    window.open(options[2], "_self")
  }
  function handleChangePlaceholder4() {
    setPlaceholder(options[3]);
    setText(options[3])
    window.open(options[3], "_self")
  }

  function handleChangePlaceholder5() {
    setPlaceholder(options[4]);
    setText(options[4])
    window.open(options[4], "_self")
  }

  let recent_searches = [];
 let header_font_size='2.5vw'
 let header_font_size2='1.2vw'
  if (window_size < 800) {
   header_font_size='5vw'
   header_font_size2='2.5vw'
    recent_searches.push(      
    <span> <p class="LazyPeopleSearch1">{" "}
    <span class="LazyPeopleSearch2">Try:{" "} </span>
      <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder1}>  {" " + options[0]} </button>  
      <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder2}>  {" " + options[1]} </button>  

      
     </p>
     
    </span>)


  }
  else
  {
    recent_searches.push(
      <span> <p class="LazyPeopleSearch1">{" "}
      <span class="LazyPeopleSearch2">Top Searches:{" "} </span>
        <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder1}>  {" " + options[0]} </button>  
        <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder2}>  {" " + options[1]} </button>  
        <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder3}>  {" " + options[2]} </button>  
        <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder4}>  {" " + options[3]} </button>
        <button class="LazyPeopleSearch33" onClick={handleChangePlaceholder5}>  {" " + options[4]} </button>

        
       </p>
       
      </span>
    ) 
  }

  
    return (
      <div>
        <Header />
        {/* {personalized_carousel} */}

        
        <div className="text_box">

          <h2 style={{ margin: '-20px -20px 20px', fontSize:header_font_size   }}>Introducing Book-Genie: Your Personal AI Book Recommendation Assistant.</h2>
          <p style={{  margin: '-12px 2px 2px',fontSize:header_font_size2   }}>Please<span class="BookGenieResultsBanner"> Refresh </span> the page or <span class="BookGenieResultsBanner"> retype </span>  if recommendations doesn't load, I can't afford a dedicated server right now as I am in bootstrapped phase.</p>
          
      <div class="input-group mb-3">
      <input type="text"
          class="form-control"
          // placeholder="Ask anything from BookGenie"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
              style={{ width: 675, height: 50 }}
              
              onChange={handleChange}

             
              placeholder={list_place} 
          onKeyPress={handleKeypress}
          />
        
        <div class="input-group-append">
              <Button type="primary" onClick={handleClick}>
        Search here
          </Button>
          </div>
      </div>
      </div>
     
{recent_searches}

        

      <span> <p class="BookGenieResultsTitle1">{" "}
        <span class="BookGenieResultsTitle2">Results for:{" "} </span>
        {" "}{" " + return_query}</p></span>

      
     {td}


    
      </div>
    );
  }


export default Community;