import React, { useState, Component } from "react";
import Header from "./Header";
import Card from "react-bootstrap/Card";
import "./ShowMore.scss";
import GoogleAnalytics from "./GoogleAnalytics";

const KitchenSinkExample = () => {
  const gaEventTracker = GoogleAnalytics("AboutUs");

  return (
    <div class="row">
      <div class="aboutus">
        <Card style={{ width: "100%" }}>
          <div className="d-flex">
            <div class="col-sm-6">
              <Card.Img variant="top" src="Ady.jpg" className="Ady-pic" />
            </div>
            <div class="col-sm-6">
              <Card.Body className="about_us_body">
                <Card.Text
                  className="about_us_text"
                  style={{ marginLeft: "20%", marginRight: "1%" }}
                >
                  <p></p>
                  <h6>
                    {" "}
                    Welcome to bookclub.ai!
                    <p></p>Technology and all its advancements have always
                    facinated me. I want to contribute towards its development
                    by applying it to the books industry.
                    <p></p>I understand that finding the perfect book can be
                    overwhelming, with so many options available. Bookclub.ai is
                    designed to help you easily discover new books based on your
                    interests and preferences. <br></br> <p></p>Oh, and I almost
                    forgot - all of this is free! That's right, I am making no
                    money out of this, just seeing new users on my website is
                    enough for me right now :)<p></p> Please don't hesitate to
                    reach out if you have any questions or feedback. I would
                    love to hear from you and I am just an email away! <br></br>
                    I hope you enjoy using my website and finding your next
                    great read. <p></p>
                    <p></p>
                    Happy reading! <p>Aditi Yadav, Founder</p>
                  </h6>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const AboutUs = () => (
  <div>
    <Header />

    {KitchenSinkExample()}
  </div>
);

export default AboutUs;
