/* This page shows all the books & their description when a given list is clicked */
import React from "react";
import { useParams } from "react-router";
import { NonDetailPageAPI } from "./MongoDB";
import { ShowAllListSeriesGenre,capitalizeFirstLetter } from "./MasterFile";
import { Helmet } from 'react-helmet-async';
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize('G-X2EQPBPN7L');


/*
Input Data - List id from MongoDB
Output Data - list books and their description
*/

const ListPage = () => {
  const params = useParams();
  const list_id = params["id"].toLowerCase()?.replace(/-/g, ' ');
  let input_key = "primary_key";

  /* Detailpage() is a reusable function to get MongoDB for series,list and genre */


  let MongodbResults = NonDetailPageAPI(list_id);
 

  /* ShowAllListSeriesGenre() is a reusable function to render books in showall page */
  return <>
    
    <Helmet>
<title>{params["id"]?.replace(/-/g, ' ')}</title>
      <meta name='description' content={"Explore all books in list " + capitalizeFirstLetter(params["id"]?.replace(/-/g, ' '))} />
    </Helmet>
    
    {ShowAllListSeriesGenre(MongodbResults, list_id)}
    <ScrollToTop smooth width={30} color="brown"  viewBox="0 0 256 256"/></>;
 
};

export default ListPage;
