import React, { useState } from "react";
import "./ShowDetails.scss";
import Masonry from "react-masonry-css";

import { Link } from "react-router-dom";
import { ErrorBoundary, combine_images } from "./MasterFile";

import ReactGA from "react-ga";
import { FiChevronRight } from "react-icons/all";
import "./Nav.scss";
const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

let window_size = window.innerWidth;
/* Defines number of cards in different screen sizes */
export const myBreakpointsAndCols = {
  default: 3,
  1100: 2,
  700: 2,
  500: 2,
  300: 2,
};

export const myBreakpointsAndColsGenres = {
  default: 5,
  1100: 5,
  700: 5,
  500: 4,
  300: 4,
};

const items = (list_data) => {
  let list_name = [
    "Best Sci Fi Romance Books",
    "Best Cryptocurrency Books",
    "Best Witchcraft Books",
  ];
  let list_images = [
    "479309,54493401,54120408,41093488",
    "38819211,82256,41433284,50175330",
    "8667848,11901,50136833,6327",
  ];
  let list_book_count = ["22", "54", "33"];
  let initial_loop = 3;

  if (window_size < 900) {
    initial_loop = 2;
  }

  let td = [];
  for (let i = 0; i < initial_loop; i++) {
    let images_list_array = list_images[i].split(",");
    if (i % 3 == 0) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage-list-home">
              <Link to={"/lists/" + list_name[i]}>
                <div class="grid-container-collage">
                  {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                  {combine_images(images_list_array)}
                </div>
              </Link>
              <p class="card-title-Lists">{list_name[i]}</p>
              <p class="card-title">{list_book_count[i] + " Books"}</p>
            </div>
          </div>
        </div>
      );
    }
    if (i % 3 == 1) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage-list2-home">
              <Link to={"/lists/" + list_name[i]}>
                <div class="grid-container-collage">
                  {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                  {combine_images(images_list_array)}
                </div>
              </Link>
              <p class="card-title-Lists">{list_name[i]}</p>
              <p class="card-title">{list_book_count[i] + " Books"}</p>
            </div>
          </div>
        </div>
      );
    }
    if (i % 3 == 2) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage-list3-home">
              <Link to={"/lists/" + list_name[i]}>
                <div class="grid-container-collage">
                  {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                  {combine_images(images_list_array)}
                </div>
              </Link>
              <p class="card-title-Lists">{list_name[i]}</p>
              <p class="card-title">{list_book_count[i] + " Books"}</p>
            </div>
          </div>
        </div>
      );
    }
  }
  return td;
};

export const items_genres = () => {
  let list_images = [];

  let initial_loop = 5;

  if (window_size < 800) {
    initial_loop = 4;
  }

  let list_name = [
    "Suspense",
    "Classics",
    "Feminism",
    "Horror",
    "Autobiography",
  ];
  let td = [];
  for (let i = 0; i < initial_loop; i++) {
    // let images_list_array = list_images[i].split(",");
    if (i % 3 == 0) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage-genre">
              <Link
                to={"/genres/all/" + list_name[i]}
                style={{ textDecoration: "none", color: "white" }}
              >
                <p class="card-title-genres">{list_name[i]}</p>
              </Link>
              {/* <p class="card-title">{list_book_count[i] + " Books"}</p> */}
            </div>
          </div>
        </div>
      );
    }
    if (i % 3 == 1) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage-genre">
              <div class="grid-container-collage">
                {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                {/* {combine_images(images_list_array)} */}
              </div>

              <Link
                to={"/genres/all/" + list_name[i]}
                style={{ textDecoration: "none", color: "white" }}
              >
                <p class="card-title-genres">{list_name[i]}</p>
              </Link>
              {/* <p class="card-title">{list_book_count[i] + " Books"}</p> */}
            </div>
          </div>
        </div>
      );
    }
    if (i % 3 == 2) {
      td.push(
        <div class="card-collage-list">
          <div key={i}>
            <div class="card-collage-genre">
              <div class="grid-container-collage">
                {/* combine_images() function from master file for combining 4 images as used in Lists and series in 2*2 matrix*/}
                {/* {combine_images(images_list_array)} */}
              </div>

              <Link
                to={"/genres/all/" + list_name[i]}
                style={{ textDecoration: "none", color: "white" }}
              >
                <p class="card-title-genres">{list_name[i]}</p>
              </Link>
              {/* <p class="card-title">{list_book_count[i] + " Books"}</p> */}
            </div>
          </div>
        </div>
      );
    }
  }
  return td;
};

export const GenresHome = () => {
  return (
    <div>
      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        <div>
          <ErrorBoundary>
            <div className="nav">
              <carousel_header>
                Recommended Genres
                <Link
                  to="/genres"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span class="name_font">
                    {" "}
                    Show all <FiChevronRight />
                  </span>
                </Link>
              </carousel_header>
            </div>

            <Masonry
              breakpointCols={myBreakpointsAndColsGenres}
              className="my-masonry-grid-genre-home"
              columnClassName="my-masonry-grid-genre-home_column"
            >
              {items_genres()}
            </Masonry>
          </ErrorBoundary>
        </div>
      </div>
      {/* <ScrollToTop smooth width={30} color="brown"  viewBox="0 0 256 256"/> */}
    </div>
  );
};

/*
Input Data- List data(list images, list name, book count)
Output Data- List Cards
*/
export const ListHome = () => {
  return (
    <div>
      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        <div>
          <ErrorBoundary>
            <div className="nav">
              <carousel_header>
                Recommended Lists
                <Link
                  to="/lists"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span class="name_font">
                    {" "}
                    Show all <FiChevronRight />
                  </span>
                </Link>
              </carousel_header>
            </div>

            <Masonry
              breakpointCols={myBreakpointsAndCols}
              className="my-masonry-grid-list-home"
              columnClassName="my-masonry-grid-list-home_column"
            >
              {items()}
            </Masonry>
          </ErrorBoundary>
        </div>
      </div>
      {/* <ScrollToTop smooth width={30} color="brown"  viewBox="0 0 256 256"/> */}
    </div>
  );
};
export default ListHome;
