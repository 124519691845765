/* This page powers List Page */
import React, { useState } from "react";
import Header from "./Header";
import "./ShowDetails.scss";
import Masonry from "react-masonry-css";
import { NavCelebrity } from "./Nav";
import { Link } from "react-router-dom";
import { CelebrityMainPage, NonDetailPageAPI } from "./MongoDB";
import { ErrorBoundary, truncate } from "./MasterFile";
import requests from "./request";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

/* Defines number of cards in different screen sizes */
const myBreakpointsAndCols = {
  default: 4,
  1100: 3,
  700: 2,
  500: 2,
};

const items = (list_data) => {
  let celebrity_name = [];
  let celebrity_images = [];
  let celebrity_book_count = [];
  let celebrity_info = [];

  let initial_loop = 5;
  for (var i = 0; i < initial_loop; i++) {
    if (list_data[0] === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(list_data[0]).length;
      celebrity_name.push(list_data[0][i]["celebrity_name"]);
      celebrity_images.push(list_data[0][i]["image_url"]);
      celebrity_book_count.push(list_data[0][i]["book_count"]);
      celebrity_info.push(list_data[0][i]["Info"]);
    }
  }

  let td = [];
  for (let i = 0; i < celebrity_name.length; i++) {
    td.push(
      <div key={i}>
        <div class="card-collage-celebrity" align="center">
          <Link
            to={"/celebrity/" + celebrity_name[i]?.replace(/ /g, "-")}
            style={{ textDecoration: "none", color: "grey" }}
          >
            <div>
              <img
                src={
                  "https://ik.imagekit.io/bookclub/" +
                  celebrity_images[i] +
                  "?tr=w-180,h-200"
                }
                width="180px"
                height="200px"
              ></img>
            </div>

            <span class="card-name-celebrity">
              {truncate(celebrity_name[i], 12)}
              <span class="celebrity_page_marker">
                {"(" + celebrity_book_count[i] + " books" + ")"}{" "}
              </span>{" "}
              {/* {" " + author_name} */}
            </span>

            {/* <h5 class="card-title-celebrity">{celebrity_info[i]}</h5> */}
          </Link>
        </div>
      </div>
    );
  }

  return td;
};

/*
Input Data- List data(list images, list name, book count)
Output Data- List Cards
*/
const Celebrity = () => {
  const [selectedOption, setselectedOption] = useState(requests.fetchAuthors);
  /* ToDo- Activate the Click Button */

  let list_page_results = NonDetailPageAPI(selectedOption);

  let list_data = [];
  for (var i = 0; i < 1; i++) {
    if (list_page_results === undefined) {
      continue;
    } else {
      list_data.push(list_page_results?.["books_list"]);
    }
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <Helmet>
        <title>
          {
            "Bookclub.ai| Explore books recommended by your favourite celebrities"
          }
        </title>
        <meta
          name="celebrities"
          content={
            "Explore books recommended by your favourite celebrities. Be part of the growing booklovers community on Bookclub.ai"
          }
        />
      </Helmet>

      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        <div style={{ margin: "10px 10px 30px 20px" }}>
          <h3>Get Inspired by Your Favourite Celebrity Book Picks.</h3>
        </div>
        <div>
          <NavCelebrity setselectedOption={setselectedOption} />
          <ErrorBoundary>
            <Masonry
              breakpointCols={myBreakpointsAndCols}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {items(list_data)}
            </Masonry>
          </ErrorBoundary>
          <ScrollToTop smooth width={30} color="brown" viewBox="0 0 256 256" />
        </div>
      </div>
    </div>
  );
};

export default Celebrity;
