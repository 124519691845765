import React from "react";
import "./search_main.scss";
import 'instantsearch.css/themes/satellite.css';

// import instantsearch from 'instantsearch.js/es';
import instantsearch from "instantsearch.js";
import { searchBox, hits } from "instantsearch.js/es/widgets";
import styled from "styled-components";
import {
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  
} from "react-instantsearch-dom";

import { useSearchBox } from 'react-instantsearch-hooks-web';
import { searchClient } from "./typesenseAdapter";
import MoviesHits from "./search_index";
import Header from "../Header";
import ScrollToTop from "react-scroll-to-top";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
`;


function Search_App() {
  return (
    
    <AppContainer>
      <Header />
      <InstantSearch indexName="books" searchClient={searchClient}>
      
        <SearchBox  classNames={{
          root: 'p-3 shadow-sm',
          form: 'relative',
          input: 'block w-full pl-9 pr-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 rounded-md focus:ring-1',
          submitIcon: 'absolute top-0 left-0 bottom-0 w-6',
        }}/>
       
        
        {/* <RefinementList
          container= '#brand-list'
          attribute="author"
          searchablePlaceholder= 'Search subjects'
          
         
        
            /> */}
        {/* <PopularQueries queries={['apple', 'samsung']} /> */}
          <MoviesHits />
        
        {/* <Pagination /> */}
  
      </InstantSearch>
      <ScrollToTop smooth width={30} color="brown"  viewBox="0 0 256 256"/>
    </AppContainer>
  );
}

export default Search_App;
