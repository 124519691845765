import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import "./ShowMore.scss";
import { Button } from "react-bootstrap";
import Community from "./Community";
import { Link } from "react-router-dom";

// Import Swiper styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import { FaChevronLeft, FaChevronRight } from "react-icons";

// import "swiper/css/bundle";
export default function Masthead() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
  };
  return (
    <div>
      <Slider {...settings}>
        <MDBCarouselItem
          itemId={1}
          src={"https://ik.imagekit.io/bookclub/Masthead33.png"}
          alt="..."
        ></MDBCarouselItem>
        <MDBCarouselItem
          itemId={1}
          src={"https://ik.imagekit.io/bookclub/Masthead11.png"}
          alt="..."
        >
          <div className="MastheadButton1">
            {" "}
            <Link to="/Community/Time%20Travel%20Romance">
              <Button variant="info">Book-Genie</Button>
            </Link>
          </div>
        </MDBCarouselItem>

        <MDBCarouselItem
          itemId={1}
          src={"https://ik.imagekit.io/bookclub/Masthead22.png"}
          alt="..."
        >
          <div className="MastheadButton1">
            {" "}
            <Link to="/MindMap/2429135-The%20Girl%20With%20Dragon%20Tattoo">
              <Button variant="info">Mind-Map</Button>
            </Link>
          </div>
        </MDBCarouselItem>
      </Slider>
    </div>
  );
}

{
  /* export default function Masthead() {
  return (
      <MDBCarousel className="custom-carousel" showControls showIndicators>
          <MDBCarouselItem
              
        className='w-100 d-block'
        itemId={1}
        src={'https://ik.imagekit.io/bookclub/Masthead11.png'}
        alt='...'
      >
      <div className='MastheadButton1'> <Link to="/Community"><Button variant="info">
  Book-Genie
</Button></Link></div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={2}
        src={'https://ik.imagekit.io/bookclub/Masthead22.png'}
        alt='...'
      >
       <div className='MastheadButton2'> <Link to="/MindMap/2429135-The%20Girl%20With%20Dragon%20Tattoo"><Button variant="info">
  Mind-Map
</Button></Link></div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={3}
        src={'https://ik.imagekit.io/bookclub/Masthead33.png'}
        alt='...'
      >
      </MDBCarouselItem>
    </MDBCarousel>
  );
}


 */
}
