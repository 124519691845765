import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import networkgraph from "highcharts/modules/networkgraph";
import UserBookShelf from "./UserBookshelf";
import Highcharts from "highcharts";
import Header from "./Header";
import { Link } from "react-router-dom";
import {
  CleanCacheDataPersonalized,
  ErrorBoundary,
  KnowledgeData,
  KnowledgeDataBook,
} from "./MasterFile";
import { useParams } from "react-router";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ReactGA from "react-ga";
import "./ShowMore.scss";
import { FiChevronRight } from "react-icons/all";

import Typesense from "typesense";
const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

let window_size = window.innerWidth;

var col1 = "#B9A7BB",
  col2 = "#C9D9F0",
  col3 = "#808080",
  col4 = "#8C9B88";

// console.log(searchSuggestions)

let placeholder = "Search for a Book or Click on a Bubble...";

if (window_size < 800) {
  placeholder = "Search here or Click on a Bubble...";
}

function Tool(book_list, author_list, book_page_list, genre_list) {
  let TOOLTIP = {
    style: {
      pointerEvents: "auto",
      width: "180px",
      whiteSpace: "normal",
    },
    formatter: function () {
      var info = "a";

      if (author_list[book_list.indexOf(this.key)].length > 0) {
        var info =
          " <b>by </b>" +
          author_list[book_list.indexOf(this.key)] +
          "<br><br>" +
          genre_list[book_list.indexOf(this.key)] +
          "<br> <br>  <a target='_blank' href=" +
          "/book/" +
          book_page_list[book_list.indexOf(this.key)] +
          "><span style='color: #FF0000'><b>Book Detail Page </b> </span></a>";
        return this.key + "<br> " + info;
      }
    },
  };
  return TOOLTIP;
}
function Nodes(
  book_list,
  book_page_list,
  base_name,
  base_url,
  num_ratings_list
) {
  let min_votes = Math.log(Math.min(...num_ratings_list));
  let max_votes = Math.log(Math.max(...num_ratings_list));

  let multiplier = (window_size + 500) / 60;

  let colors = [col1, col2, col3, col4];
  let node_list_knowledge_graph = [];
  let half_way_mark = ~~(book_page_list.length / 2);
  for (let i = 0; i < book_page_list.length; i++) {
    if (i == ~~(0.8 * half_way_mark)) {
      node_list_knowledge_graph.push({
        id: "",
        name: "",
        marker: {
          radius: 70,
          symbol: base_url,
        },
      });
    } else {
      node_list_knowledge_graph.push({
        id: "/MindMap/" + book_page_list[i] + "-" + book_list[i],
        name: book_list[i],
        marker: {
          radius: Math.max(
            (multiplier * (Math.log(num_ratings_list[i]) - min_votes)) /
              (max_votes - min_votes),
            2
          ),
        },
        color: colors[0],
      });
    }
  }
  let NODES = [
    {
      cursor: "pointer",
      marker: {
        states: {
          hover: {
            enabled: true,
            radius: 20,
          },
        },
      },
      states: {
        hover: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: true,
        linkFormat: "",
      },

      nodes: node_list_knowledge_graph,
    },
  ];
  return NODES;
}
const handlePointClick = (e) => {
  // console.log(e.point.id)
  window.open(e.point.id);
  // window.open("www.bookclub.ai","_self");
};
let PLOT = {
  networkgraph: {
    keys: ["from", "to"],
    point: {
      events: {
        click(e) {
          handlePointClick(e);
        },
        mouseOver(e) {},
      },
    },
    layoutAlgorithm: {
      enableSimulation: true,
      integration: "verlet",
      maxSpeed: 1000,

      friction: -0.9,
    },
  },
};

function Chart(
  book_list,
  author_list,
  book_page_list,
  genre_list,
  num_ratings_list,
  base_name,
  base_url
) {
  let options = {
    chart: {
      //   backgroundColor: "#F5F5F5",
      backgroundColor: "transparent",
      type: "networkgraph",
      // height: "40%",
      // Set the chart to be responsive
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: "45%",
              animation: {
                duration: 500,
              },
            },
          },
        },
        {
          breakpoint: 1000,
          options: {
            chart: {
              height: "45%",
              animation: {
                duration: 500,
              },
            },
          },
        },
        {
          breakpoint: 800,
          options: {
            chart: {
              height: "85%",
              animation: {
                duration: 500,
              },
            },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              height: "85%",
              animation: {
                duration: 500,
              },
            },
          },
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              height: "85%",
              animation: {
                duration: 500,
              },
            },
          },
        },
      ],
    },
    title: {
      text: "",
    },
    tooltip: Tool(book_list, author_list, book_page_list, genre_list),
    plotOptions: PLOT,
    series: Nodes(
      book_list,
      book_page_list,
      base_name,
      base_url,
      num_ratings_list
    ),
  };
  return options;
}

function KnowledgeGraphHome() {
  // const params = useParams();
  // const kg_id = params["id"]?.split('-')[0];
  const kg_id = "27276318";

  const [book_list, author_list, book_page_list, genre_list, num_ratings_list] =
    KnowledgeDataBook(kg_id, window_size);

  let base_name = kg_id;

  let image_height = 180;
  let image_width = 120;
  let header_font_size = "2.5vw";
  if (window_size < 800) {
    image_height = 120;
    image_width = 80;
    header_font_size = "5vw";
  }
  let base_url =
    "url(https://ik.imagekit.io/bookclub/" +
    kg_id +
    ".jpg?tr=w-" +
    image_width +
    ",h-" +
    image_height +
    ")";

  if (book_list.length == 0) {
    return (
      <>
        {/* <div>
          <Header />{" "}
        </div> */}
      </>
    );
  } else {
    return (
      <>
        <div>
          {/* <Header /> */}
          <div style={{ margin: "20px 10px 20px 20px" }}>
            <div className="nav">
              <carousel_header>
                Mind Map
                <Link
                  to="/MindMap/2429135-The%20Girl%20With%20Dragon%20Tattoo"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span class="name_font">
                    {" "}
                    Show all <FiChevronRight />
                  </span>
                </Link>
              </carousel_header>
            </div>
          </div>
          {/* <div style={{ margin: "20px" }}>{SearchInput()}</div> */}
          <ErrorBoundary>
            {/* {LeftSideTextContainer()} */}
            <div>
              {/* <div style={{ color: "grey", margin: "-20px 10px 20px 35px" }}>
                <h10>Size of a bubble denotes the book's popularity.</h10>
              </div> */}
              <div className="Mind-Map-Home">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={Chart(
                    book_list,
                    author_list,
                    book_page_list,
                    genre_list,
                    num_ratings_list,
                    base_name,
                    base_url
                  )}
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </>
    );
  }
}

export default KnowledgeGraphHome;
