import React, { useState } from "react";
import "./Header.css";
import "wired-elements";
import Dropdown from "react-bootstrap/Dropdown";

import { Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

import { Link } from "react-router-dom";

import "./Navbar.css";
import KnowledgeGraph from "./KnowledgeGraph";
import ReactGA from "react-ga";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

let window_size = window.innerWidth;

let search_button = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="currentColor"
    class="bi bi-search"
    viewBox="0 0 16 16"
  >
    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
  </svg>
);

export function Navbar({ backgroundColor, fontColor, brand, links }) {
  const [isMenuClosed, setMenuClosed] = useState(true);

  return (
    <div
      className="nav-container"
      style={{ backgroundColor: backgroundColor, color: fontColor }}
    >
      <nav>
        <div
          className="nav-hamburguer-icon"
          onClick={() => setMenuClosed(!isMenuClosed)}
        >
          {" "}
          <div className="nav-mobile-brand">
            {" "}
            {"☰"}
            <wired-button
              id="openDialog"
              onclick="window.location.href='https://www.bookclub.ai/'"
            >
              bookclub.ai
            </wired-button>
          </div>{" "}
        </div>

        <div className="nav-links">
          {/* <div className="nav-brand">{brand}</div> */}

          <div className="nav-brand">
            <wired-button
              id="openDialog"
              onclick="window.location.href='https://www.bookclub.ai/'"
            >
              bookclub.ai
            </wired-button>{" "}
            <p>
              <div className="nav-brand-1">A book discovery website</div>
            </p>
          </div>
          {/* <wired-card elevation="5"><h4>bookclub.ai</h4></wired-card> */}

          {links.map((node) => {
            return (
              <Link key={node.text} to={node.link} style={{ color: fontColor }}>
                {node.text}
              </Link>
            );
          })}

          {/* <Dropdown>
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              style={{ color: fontColor }}
            >
              Browse
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/celebrity">Celebrity-Picks</Dropdown.Item>
              <Dropdown.Item href="/lists">Reading-Lists</Dropdown.Item>
              <Dropdown.Item href="/series">Book-Series</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          <Button href="/search" target="_blank">
            {search_button}
          </Button>

          {/* href="https://books-search.typesense.org/?_ga=2.14181943.1087595353.1666234302-366817150.1666234302"> */}

          <div className="nav_nodes">{/* <Test  /> */}</div>
        </div>
      </nav>
      <div className={`overlay-container${isMenuClosed ? "-hidden" : "-show"}`}>
        <div style={{ backgroundColor: backgroundColor }}>
          <div
            className="overlay-buttonClose"
            onClick={() => setMenuClosed(!isMenuClosed)}
          >
            <span>&times;</span>
          </div>

          <div className="overlay-menu-wrapper">
            <div className="overlay-menu">
              {links.map((node) => {
                return (
                  <Link
                    key={node.text}
                    to={node.link}
                    onClick={() => setMenuClosed(!isMenuClosed)}
                    className="overlay-menu-item"
                    style={{ color: fontColor }}
                  >
                    {node.text}
                  </Link>
                );
              })}
              {/* <Test/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Navbar.defaultProps = {
  backgroundColor: "white",
  fontColor: "black",
  brand: "BookClub.ai",
  links: [
    { text: "Home", link: "/" },

    { text: "Book-Genie", link: "/Community/Time%20Travel%20Romance" },
    { text: "Mind-Map", link: "/MindMap/2429135-The Girl With Dragon Tattoo" },
    { text: "Categories", link: "/genres" },

    { text: "Celebrity-Picks", link: "/celebrity" },
    { text: "Reading-Lists", link: "/lists" },
    { text: "Book-Series", link: "/series" },
    // { text: "Authors", link: "#" },
    // { text: "My-Books", link: "/ProfilePage" },
    // { text: "Login", link: "/login" },
    // { text: "Discover", link: "/Discover" },
  ],
};

if (window_size < 600) {
  Navbar.defaultProps = {
    backgroundColor: "white",
    fontColor: "black",
    brand: "BookClub.ai",
    links: [
      { text: "Home", link: "/" },

      { text: "Book-Genie", link: "Community/Time%20Travel%20Romance" },
      {
        text: "Mind-Map",
        link: "/MindMap/2429135-The Girl With Dragon Tattoo",
      },
      { text: "Categories", link: "/genres" },

      { text: "Celebrity-Picks", link: "/celebrity" },
      { text: "Reading-Lists", link: "/lists" },
      { text: "Book-Series", link: "/series" },
      { text: "Search", link: "/search" },
      // { text: "Authors", link: "#" },
      // { text: "My-Books", link: "/ProfilePage" },
      // { text: "Login", link: "/login" },
      // { text: "Discover", link: "/Discover" },
    ],
  };
}

function Header() {
  return <Navbar />;
}

export default Header;
