import React, { useState } from "react";
import Header from "./Header";
import "./ShowDetails.scss";
import Masonry from "react-masonry-css";
import { NavCelebrity } from "./Nav";
import { Link } from "react-router-dom";

import { ErrorBoundary, truncate } from "./MasterFile";
import requests from "./request";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import ReactGA from "react-ga";
import "./Nav.scss";
import { FiChevronRight } from "react-icons/all";
const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

let window_size = window.innerWidth;

/* Defines number of cards in different screen sizes */
const myBreakpointsAndCols = {
  default: 4,
  1100: 4,
  700: 4,
  500: 3,
};
const items = () => {
  let celebrity_name = [
    "Elon Musk",
    "Bill Gates",
    "Warren Buffett",
    "Steve-Jobs",
  ];
  let celebrity_images = [
    "elon-musk",
    "bill-gates",
    "warren-buffett",
    "steve-jobs",
  ];
  let celebrity_book_count = [61, 52, 37, 34];
  let initial_loop = 4;
  if (window_size < 700) {
    initial_loop = 3;
  }

  let td = [];
  for (let i = 0; i < initial_loop; i++) {
    td.push(
      <div key={i}>
        <div class="card-collage-celebrity" align="center">
          <Link
            to={"/celebrity/" + celebrity_name[i]?.replace(/ /g, "-")}
            style={{
              textDecoration: "none",
              color: "grey",
            }}
          >
            <div>
              <img
                src={
                  "https://ik.imagekit.io/bookclub/" +
                  celebrity_images[i] +
                  ".jpg?tr=w-180,h-200"
                }
                width="180px"
                height="200px"
              ></img>
            </div>
            <span class="card-name-celebrity" align="center">
              {truncate(celebrity_name[i], 12)}
              <p>
                {" "}
                <span class="celebrity_page_marker" align="center">
                  {"(" + celebrity_book_count[i] + " books" + ")"}{" "}
                </span>{" "}
              </p>
              {/* {" " + author_name} */}
            </span>{" "}
            {/* <h5 class="card-title-celebrity">{celebrity_info[i]}</h5> */}
          </Link>
        </div>
      </div>
    );
  }
  return td;
};
/*
Input Data- List data(list images, list name, book count)
Output Data- List Cards
*/
const CelebrityHome = () => {
  return (
    <div className="CelebrityHome">
      <div className="w-auto p-3" style={{ backgroundColor: "#eee" }}>
        <div>
          <ErrorBoundary>
            <div className="nav">
              <carousel_header>
                Celebrity Picks
                <Link
                  to="/celebrity"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <span class="name_font">
                    {" "}
                    Show all <FiChevronRight />
                  </span>
                </Link>
              </carousel_header>
            </div>
            <Masonry
              breakpointCols={myBreakpointsAndCols}
              className="my-masonry-grid-celebrity-home"
              columnClassName="my-masonry-grid-celebrity-home_column"
            >
              {items()}
            </Masonry>
          </ErrorBoundary>
          <ScrollToTop smooth width={30} color="brown" viewBox="0 0 256 256" />
        </div>
      </div>
    </div>
  );
};
export default CelebrityHome;
