import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ShowMore.scss";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/all";
import Header from "./Header";
import "./Nav.scss";
import ReactCardFlipper from "react-card-flipper";
import { WiredLink } from "wired-elements";
import { WiredDialog } from "wired-elements";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { MDBBtn } from "mdb-react-ui-kit";
import Rating from "react-rating";
import ScrollToTop from "react-scroll-to-top";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import LazyLoad from "react-lazy-load";
// import { Write_Mongo,Write_Mongo_Detail,Write_Mongo_Browse_History } from "./MongoDB";
import * as Icon from "react-bootstrap-icons";
import {
  Homepage_data,
  MoreLikeThisPersonalized,
  DetailPageAPI,
  NonDetailPageAPI,
} from "./MongoDB";
import ReactGA from "react-ga";

import Modal from "react-bootstrap/Modal";
import { motion } from "framer-motion";

const TRACKING_ID = "G-X2EQPBPN7L";
ReactGA.initialize("G-X2EQPBPN7L");

export const cookieValue = document.cookie?.split("; ")[0];

export const defaultEasing = [0.6, -0.05, 0.01, 0.99];

export const staggerOne = {
  animate: { transition: { staggerChildren: 0.1 } },
};

export const modalFadeInUpVariants = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.8, ease: defaultEasing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.8, ease: defaultEasing },
  },
};

let empty_star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="black"
    class="bi bi-star"
    viewBox="0 0 16 16"
  >
    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
  </svg>
);
let complete_star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="black"
    class="bi bi-star-fill"
    viewBox="0 0 16 16"
  >
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
  </svg>
);

let empty_star_flippy = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="black"
    class="bi bi-star"
    viewBox="0 0 16 16"
  >
    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
  </svg>
);
let complete_star_flippy = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="black"
    class="bi bi-star-fill"
    viewBox="0 0 16 16"
  >
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
  </svg>
);

let window_size = window.innerWidth;

/*
This component decides how many items to show in HomePage carousel
*/
export const responsive = {
  largescreen: {
    breakpoint: { max: 2500, min: 1200 },
    items: 6.5,
    slidesToSlide: 4,
  },
  largescreen2: {
    breakpoint: { max: 1200, min: 992 },
    items: 6.3,
    slidesToSlide: 4,
  },

  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 3.2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 576 },
    items: 2.3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 2.2,
    slidesToSlide: 1,
  },
};

/*
This component decides how many items to show in Series carousel
*/
export const responsive_series = {
  largescreen: {
    breakpoint: { max: 2500, min: 1200 },
    items: 6.3,
    slidesToSlide: 4,
  },
  largescreen2: {
    breakpoint: { max: 1200, min: 992 },
    items: 5.3,
    slidesToSlide: 4,
  },

  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 4.3,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 768, min: 576 },
    items: 3.6,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 2.8,
    slidesToSlide: 3,
  },
};

/*
This component adds a dropdown button to add to reading list,Read, Currently reading etc
*/
// export function BookAction(book_name,sequence) {
//   const handleSelect = (e) => {
//   let id=cookieValue

//     { Write_Mongo(id,e,book_name[0][sequence]) }
//   }

//   return (
//     <Dropdown onSelect={handleSelect}>

//       <Dropdown.Toggle variant="success" id="dropdown-basic" >
//       To Read
//       </Dropdown.Toggle>

//       <Dropdown.Menu >
//         <Dropdown.Item eventKey="To Read" >To Read  </Dropdown.Item>
//         <Dropdown.Item eventKey="Currently Reading" >Currently Reading </Dropdown.Item>
//         <Dropdown.Item eventKey="Read" >Read </Dropdown.Item>
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// }

// To source data for knowledge graph
export function KnowledgeData(genre) {
  let book_list = [];
  let author_list = [];
  let book_page_list = [];
  let personalized_carousel = DetailPageAPI(genre);

  if (personalized_carousel != undefined) {
    let initial_loop = 12;
    for (var i = 0; i < 30; i++) {
      initial_loop = Object.keys(personalized_carousel["books_list"]).length;
      book_list.push(
        personalized_carousel["books_list"][i]?.["book_title_goodreads"].split(
          ":"
        )[0]
      );
      author_list.push(personalized_carousel["books_list"][i]?.["author"]);
      book_page_list.push(
        personalized_carousel["books_list"][i]?.["image_name2"]
      );
    }
  }

  return [book_list, author_list, book_page_list];
}

// To source data for knowledge graph for Detail Page
export function KnowledgeDataBook(kg_id, window_size) {
  let book_list = [];
  let author_list = [];
  let book_page_list = [];
  let genre_list = [];
  let num_ratings_list = [];
  let personalized_carousel = DetailPageAPI(kg_id);

  if (personalized_carousel != undefined) {
    const [home_data2, carousel_names] = CleanMongoDBDataAPI(
      personalized_carousel
    );
    let initial_loop = 50;
    if (window_size < 900) {
      initial_loop = 30;
    }

    for (var i = 0; i < initial_loop; i++) {
      if (home_data2 === undefined) {
        continue;
      } else {
        initial_loop = Math.min(home_data2.length, initial_loop);
        book_list.push(home_data2[i]?.["book_title_goodreads"].split(":")[0]);
        author_list.push(home_data2[i]?.["author"]);
        book_page_list.push(home_data2[i]?.["image_name2"]);
        genre_list.push(
          home_data2[i]?.["genres"].replace(" ", "").split(",").slice(0, 2)
        );
        num_ratings_list.push(parseInt(home_data2[i]?.["num_ratings"]));
      }
    }
  }

  return [book_list, author_list, book_page_list, genre_list, num_ratings_list];
}

/*
<<Carousel Only>> This component adds a dropdown button to add to reading list,Read, Currently reading etc
*/
// export function BookActionCarousel(book_name,sequence) {
//   const handleSelect = (e) => {
//   let id=cookieValue

//     { Write_Mongo(id,e,book_name[0][sequence]) }
//   }

//   return (
//     <Dropdown onSelect={handleSelect}>

//       <Dropdown.Toggle variant="light" id="dropdown-basic" >

//       </Dropdown.Toggle>

//       <Dropdown.Menu >
//         <Dropdown.Item eventKey="To Read" >To Read  </Dropdown.Item>
//         <Dropdown.Item eventKey="Currently Reading" >Currently Reading </Dropdown.Item>
//         <Dropdown.Item eventKey="Read" >Read </Dropdown.Item>
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// }

export const truncate = (text, n) =>
  text?.length > n ? text.substr(0, n - 1) + "..." : text;

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

/* Detail Page component */

export const detail_page_card = (book_info) => {
  // { Write_Mongo_Browse_History("Browse History", book_info) }

  let td = [];
  const description = capitalizeFirstLetter(
    truncate(book_info?.description, 500)
  );

  let num_categories = book_info["genres"]?.split(",").length;

  if (window_size < 600) {
    var description_book = truncate(description, 290);

    td.push(
      <div class="container">
        <div class="row">
          <div class="detail-page-top">
            <img
              src={
                "https://ik.imagekit.io/bookclub/" +
                book_info["image_name2"] +
                ".jpg?tr=w-300,h-500"
              }
              width="300"
              height="500"
              alt={book_info["book_title_goodreads"]}
            />
          </div>

          <div class="detail-page-top-info">
            <p class="card-text3"> {book_info["book_title_goodreads"]} </p>

            <span class="detail_page_info">
              {" "}
              <span class="detail_page_marker">Author: </span>{" "}
              {" " + book_info?.author}
            </span>
            <p>
              <span class="detail_page_info_rating">
                {" "}
                <span class="detail_page_marker">Rating: </span>{" "}
                {
                  <Rating
                    placeholderRating={Number(
                      book_info?.average_rating
                    ).toFixed(2)}
                    step={1}
                    fractions={10}
                    readonly
                    placeholderSymbol={complete_star_flippy}
                    emptySymbol={empty_star_flippy}
                  />
                }{" "}
                {" " + book_info?.average_rating}{" "}
                {" (" + (book_info.num_ratings / 1000).toFixed() + "k)"}
              </span>
            </p>
            {book_info?.year_first_published != "" && (
              <span>
                <p class="detail_page_info_year">
                  {" "}
                  <span class="detail_page_marker">
                    Year First Published:{" "}
                  </span>{" "}
                  {" " + book_info?.year_first_published}
                </p>
              </span>
            )}
            <div>
              <h41 class="nav">
                {tags_rectangle_box(
                  book_info["genres"]?.split(","),
                  num_categories
                )}
              </h41>
            </div>
          </div>

          <div>
            {book_info?.series_name.length > 0 && (
              <p class="card-detail-series">
                {" "}
                This book is part of{" "}
                <wired-link
                  elevation="1.3"
                  href={"/series/" + book_info?.series_name.toLowerCase()}
                  target="_blank"
                >
                  {book_info?.series_name}
                </wired-link>{" "}
                series
              </p>
            )}

            <p class="card-detail-description"> {description_book} </p>

            {/* 
            <MDBBtn className="cast__books-button" href="#">
              Buy Now
            </MDBBtn> */}
          </div>
        </div>
      </div>
    );
  } else {
    var description_book = truncate(description, 450);
    td.push(
      <div class="container">
        <div class="row">
          <div class="detail-page-top">
            <img
              src={
                "https://ik.imagekit.io/bookclub/" +
                book_info["image_name2"] +
                ".jpg?tr=w-300,h-500"
              }
              width="300"
              height="500"
              alt={book_info?.book_title_goodreads}
            />
          </div>

          <div class="col-sm">
            <p class="card-text3"> {book_info?.book_title_goodreads} </p>
            <span class="detail_page_info">
              {" "}
              <span class="detail_page_marker">Author: </span>{" "}
              {" " + book_info?.author}
            </span>
            <p class="detail_page_info">
              {" "}
              <span class="detail_page_marker">Ratings: </span>{" "}
              {
                <Rating
                  placeholderRating={Number(book_info?.average_rating).toFixed(
                    2
                  )}
                  step={1}
                  fractions={10}
                  readonly
                  placeholderSymbol={complete_star}
                  emptySymbol={empty_star}
                />
              }{" "}
              {" " + book_info?.average_rating}{" "}
              {" (" + (book_info.num_ratings / 1000).toFixed() + "k)"}
            </p>
            {book_info?.year_first_published != "" && (
              <span>
                <p class="detail_page_info_year">
                  {" "}
                  <span class="detail_page_marker">
                    Year First Published:{" "}
                  </span>{" "}
                  {" " + book_info?.year_first_published}
                </p>
              </span>
            )}
            <div>
              <h41 class="nav">
                {tags_rectangle_box(
                  book_info["genres"]?.split(","),
                  num_categories
                )}
              </h41>
            </div>
            <div>
              {book_info?.series_name.length > 0 && (
                <p class="card-detail-series">
                  {" "}
                  This book is part of{" "}
                  <wired-link
                    elevation="1.3"
                    href={"/series/" + book_info?.series_name.toLowerCase()}
                    target="_blank"
                  >
                    {book_info?.series_name}
                  </wired-link>{" "}
                  series
                </p>
              )}

              <p class="card-detail-description"> {description_book} </p>
              {/* <p class="ml-3"> {BookAction('test')}</p> */}
              {/* 
          <MDBBtn className="cast__books-button" href="#">
            Buy Now
          </MDBBtn> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return td;
};

/* Make first letter as Capital */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/* Clean Data received from MongoDB and break it into Carousel and underlying items */
export function CleanMongoDBData(raw_results) {
  let initial_list = [];
  let initial_loop = 20;
  for (var i = 0; i < initial_loop; i++) {
    if (raw_results === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(raw_results[0]?.["books_list"]).length;
      initial_list.push(raw_results[0]?.["books_list"][i]);
    }
  }

  let carousel_names = initial_list
    .map((item) => item.carousel)
    .filter((value, index, self) => self.indexOf(value) === index);

  return [initial_list, carousel_names];
}

/* Clean Data received from MongoDB and break it into Carousel and underlying items */
export function CleanMongoDBDataAPI(raw_results) {
  let initial_list = [];
  let initial_loop = 20;
  for (var i = 0; i < initial_loop; i++) {
    if (raw_results === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(raw_results?.["books_list"]).length;
      initial_list.push(raw_results?.["books_list"][i]);
    }
  }

  let carousel_names = initial_list
    .map((item) => item.carousel)
    .filter((value, index, self) => self.indexOf(value) === index);

  return [initial_list, carousel_names];
}

/* Clean Data received from MongoDB and break it into Carousel and underlying items */
export function CleanMongoDBDataModal(raw_results) {
  let initial_list = [];
  let initial_loop = 20;
  for (var i = 0; i < initial_loop; i++) {
    if (raw_results === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(raw_results[0]?.["books_list"]).length;
      initial_list.push(raw_results[0]?.["books_list"][i]);
    }
  }

  return initial_list;
}
/* Clean Cache Data for high performance */
export function CleanCacheData(raw_results) {
  let initial_list = [];
  let initial_loop = 20;
  for (var i = 0; i < initial_loop; i++) {
    if (raw_results === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(raw_results["books_list"]).length;
      initial_list.push(raw_results["books_list"][i]);
    }
  }

  let carousel_names = initial_list
    .map((item) => item?.carousel)
    .filter((value, index, self) => self.indexOf(value) === index);

  return [initial_list, carousel_names];
}

/* Clean Cache Personalized Data for high performance */
export function CleanCacheDataPersonalized(raw_results) {
  let initial_list = [];
  let title_names = "";
  let initial_loop = 20;
  for (var i = 0; i < initial_loop; i++) {
    if (raw_results === undefined) {
      continue;
    } else {
      initial_loop = 12;
      let last_book = Object.keys(raw_results["books_list"]).length - 1;

      initial_list.push(raw_results["books_list"][last_book][i]);

      title_names = raw_results["title"][last_book];
    }
  }

  let carousel_names = initial_list
    .map((item) => item.carousel)
    .filter((value, index, self) => self.indexOf(value) === index);

  return [initial_list, carousel_names, title_names];
}

/* For MoreLikeThis on Modal */
export function CleanCacheDataModal(raw_results) {
  let initial_list = [];
  let title_names = "";
  let initial_loop = 1;
  for (var i = 0; i < 1; i++) {
    if (raw_results === undefined) {
      continue;
    } else {
      initial_list.push(raw_results["books_list"]);
    }
  }

  let carousel_names = initial_list
    .map((item) => item.carousel)
    .filter((value, index, self) => self.indexOf(value) === index);

  return [initial_list, carousel_names];
}

/* Function to combine 4 images for Lists and series 
SampleURL: https://www.bookclub.ai/series/all/Best%20Fantasy%20Series
*/
export const combine_images = (images_list_array) => {
  let td = [];
  for (let i = 0; i < 4; i++) {
    if (images_list_array[i] >= 0) {
      td.push(
        <img
          src={
            "https://ik.imagekit.io/bookclub/" +
            images_list_array[i] +
            ".jpg?tr=w-180,h-120"
          }
          alt="Add Book"
          width="180px"
          height="120px"
        ></img>
      );
    } else {
      td.push(
        <img
          src={
            "https://ik.imagekit.io/bookclub/sample_image_2.png?tr=w-180,h-120"
          }
          alt="Add Book"
          width="180px"
          height="120px"
        ></img>
      );
    }
  }

  return td;
};

/*  
Input Data- Homepage carousel directly from MongoDB
Output Data- Dataset for rendering in MasterFile
Description - Converting KeyValue to List
*/
export const carousel_parse_all = (
  list_data,
  carousel_names,
  ref,
  page_separator,
  title_name,
  personalized_title
) => {
  let td = [];
  if (title_name?.length > 0) {
    let MoreLikeThis = list_data.filter(
      (carousel) => carousel.carousel === "More Like This"
    );
    // if (MoreLikeThis?.length > 10)
    // { Write_Mongo_Detail("Detail Page Personalized", MoreLikeThis, title_name) }
  }
  for (let i = 0; i < carousel_names.length; i++) {
    if (personalized_title == "sample") {
      carousel_names[i] = carousel_names[i] + " Testing";
    }
    td.push(
      carousel_render(
        capitalizeFirstLetter(carousel_names[i]),
        list_data.filter((carousel) => carousel.carousel === carousel_names[i]),
        ref,
        page_separator
      )
    );
  }
  return td;
};

/*  
Input Data- Homepage carousel directly from MongoDB
Output Data- Dataset for rendering in MasterFile
Description - Converting KeyValue to List
*/
export const carousel_parse_all_homepage_personalization = (
  list_data,
  carousel_names,
  ref,
  page_separator,
  personalized_title
) => {
  let td = [];

  for (let i = 0; i < carousel_names.length; i++) {
    td.push(
      carousel_render(
        capitalizeFirstLetter(
          carousel_names[i]?.replace("This", "") + " " + personalized_title
        )
          ?.replace("undefined", "")
          ?.replace("Undefined", "Continue Exploring..."),
        list_data.filter((carousel) => carousel.carousel === carousel_names[i]),
        ref,
        page_separator
      )
    );
  }
  return td;
};

export const carousel_parse_all_homepage_personalization_non_show_all = (
  list_data,
  carousel_names,
  ref,
  page_separator,
  personalized_title
) => {
  let td = [];

  for (let i = 0; i < carousel_names.length; i++) {
    td.push(
      carousel_render_not_show_all(
        capitalizeFirstLetter(
          carousel_names[i]?.replace("This", "") + " " + personalized_title
        )
          ?.replace("undefined", "")
          ?.replace("Undefined", "Continue Exploring..."),
        list_data.filter((carousel) => carousel.carousel === carousel_names[i]),
        ref,
        page_separator
      )
    );
  }
  return td;
};

export function carousel_render_not_show_all(
  name,
  list_data,
  ref,
  page_location
) {
  return (
    <>
      <div className="nav">
        <carousel_header>{name}</carousel_header>
      </div>

      <div className="carousel_class">
        <Carousel
          partialVisible={true}
          itemClass="carousel-item-padding-40-px"
          responsive={responsive}
          ssr={true}
          // showDots={true}
          keyBoardControl={false}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          minimumTouchDrag={5}
        >
          {list_data.map((movie) => carousel_books(movie, ref))}
        </Carousel>
      </div>
    </>
  );
}

let carousel_height = "320px";
if (window_size < 600) {
  carousel_height = "210px";
}

if ((window_size > 600) & (window_size < 900)) {
  carousel_height = "350px";
}

/*
InputData- Individual Book data 
OutputData-Actual Items within carousel
Description- Books within the carousel including card, image, flippy and description. Used in HomePage and Genre
*/

export const carousel_books = (books, ref) => {
  let td = [];

  td.push(
    <>
      <div className="FlippyCard">
        <Link
          to={
            "/book/" +
            books["book_title_goodreads"]?.replace(" ", "-") +
            "-" +
            books["image_name2"]
          }
          target="_blank"
        >
          <ReactCardFlipper
            width="100%"
            height={carousel_height}
            behavior="hover"
            levitate={false}
          >
            <div>
              <img
                src={
                  "https://ik.imagekit.io/bookclub/" +
                  books["image_name2"] +
                  ".jpg?tr=w-180,h-300"
                }
                width="180px"
                height="300px"
                alt={books["book_title_goodreads"]}
              ></img>
              {/* <div className="carousel-dropdown">
            {BookActionCarousel(('a1','a2'))}
           
      </div>    */}
            </div>

            <div>
              {/* <MDBBtn class="btn btn-success" href="www.google.com">
                  Quick View
                </MDBBtn> */}
              <h3 class="card-flip-book-name">
                {books["book_title_goodreads"]}
              </h3>
              <h6 class="card-flip-author-name">{"by " + books?.author}</h6>
              <span class="card-flip-author-name">
                {" "}
                {
                  <Rating
                    placeholderRating={Number(books?.average_rating).toFixed(2)}
                    step={1}
                    fractions={10}
                    readonly
                    placeholderSymbol={complete_star_flippy}
                    emptySymbol={empty_star_flippy}
                  />
                }{" "}
                {" (" + (books.num_ratings / 1000).toFixed() + "k)"}
              </span>

              {/* <div class="nav_all">{"Genres"} </div>  */}
              <br></br>
              <p></p>
              <h6 class="card-flip-description">
                {truncate(books["description"], 300)}
              </h6>

              {/* <MDBBtn href='#'><i class={buy_icon}></i></MDBBtn> */}
            </div>
          </ReactCardFlipper>
        </Link>
      </div>
    </>
  );

  return td;
};

/*  
To show Recommended carousels on Detail page without show_all
*/
export const carousel_parse_detail_page = (
  list_data,
  carousel_names,
  ref,
  page_separator,
  title_name,
  personalized_title
) => {
  let td = [];
  if (title_name?.length > 0) {
    let MoreLikeThis = list_data.filter(
      (carousel) => carousel.carousel === "More Like This"
    );
    // if (MoreLikeThis?.length > 10)
    // { Write_Mongo_Detail("Detail Page Personalized", MoreLikeThis, title_name) }
  }
  for (let i = 0; i < carousel_names.length; i++) {
    if (personalized_title == "sample") {
      carousel_names[i] = carousel_names[i] + " Testing";
    }
    td.push(
      carousel_render_not_show_all(
        capitalizeFirstLetter(carousel_names[i]),
        list_data.filter((carousel) => carousel.carousel === carousel_names[i]),
        ref,
        page_separator
      )
    );
  }
  return td;
};

/* This is for tags on ShowAll page 
Example: [YoungAdult,Investing] on https://www.bookclub.ai/series/the%20hunger%20games
*/

function tags_rectangle_box(results, num_categories) {
  let td = [];

  for (let i = 0; i < num_categories; i++) {
    if (results === undefined) {
      continue;
    } else {
      td.push(
        <h2_detail_page key={results[i]}>
          <Link
            to={"/genres/all/" + results[i].split("-")[0].toLowerCase()}
            target="_blank"
            style={{ textDecoration: "none", color: "black" }}
          >
            {capitalizeFirstLetter(results[i])}
          </Link>
        </h2_detail_page>
      );
    }
  }
  return td;
}

/*
InputData- Tag for the list/series/genre for which show all page is generated
OutputData-Show All page
Description- This is a reusable function which shows all the books within a tag
Sample url- https://www.bookclub.ai/lists/Best%20Young%20Adult%20Books
*/

export const show_all_page = (list_data) => {
  let list_book_name = [];
  let list_book_description = [];
  let list_book_avg_rating = [];
  let list_book_rating_count = [];
  let list_book_genres = [];
  let list_book_year_published = [];
  let list_image = [];
  let list_author_name = [];

  let initial_loop = 4;
  for (var i = 0; i < initial_loop; i++) {
    if (list_data[0] === undefined) {
      continue;
    } else {
      initial_loop = Object.keys(list_data[0]).length;

      list_book_name.push(list_data[0][i]["book_title_goodreads"]);
      list_book_description.push(list_data[0][i]["description"]);
      list_book_avg_rating.push(list_data[0][i]["average_rating"]);
      list_book_rating_count.push(list_data[0][i]["num_ratings"]);
      list_book_genres.push(list_data[0][i]["genres"]);
      list_book_year_published.push(list_data[0][i]["year_first_published"]);
      list_image.push(list_data[0][i]["image_name2"]);
      list_author_name.push(list_data[0][i]["author"]);
    }
  }

  let td2 = [];

  for (let card_loop = 0; card_loop < initial_loop; card_loop++) {
    let td = [];
    for (let i = 0; i < 1; i++) {
      var image_book = list_image[card_loop];

      var rating_book = list_book_avg_rating[card_loop];
      var rating_count = list_book_rating_count[card_loop];
      var year_published = list_book_year_published[card_loop];

      let num_categories = list_book_genres[card_loop]?.split(",").length;
      if (window_size < 600) {
        var description_book = truncate(list_book_description[card_loop], 290);
        var book_name = truncate(list_book_name[card_loop], 18);
        var author_name = truncate(list_author_name[card_loop], 15);
        td.push(
          <div class="card_show_all">
            <div class="row no-gutters mb-4">
              <div class="detail-page-top">
                <Link
                  to={
                    "/book/" +
                    list_book_name[card_loop]?.replace(/ /g, "-") +
                    "-" +
                    image_book
                  }
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={
                      "https://ik.imagekit.io/bookclub/" +
                      image_book +
                      ".jpg?tr=w-300,h-500"
                    }
                    width="300"
                    height="500"
                    alt={book_name}
                  />
                </Link>
              </div>

              <div class="detail-page-top-info">
                <Link
                  to={
                    "/book/" +
                    list_book_name[card_loop]?.replace(/ /g, "-") +
                    "-" +
                    image_book
                  }
                  style={{ textDecoration: "none" }}
                >
                  <p class="card-text3"> {book_name} </p>

                  <span class="detail_page_info">
                    {" "}
                    <span class="detail_page_marker">Author: </span>{" "}
                    {" " + author_name}
                  </span>
                  <p>
                    <span class="detail_page_info_rating">
                      {" "}
                      <span class="detail_page_marker">Rating: </span>{" "}
                      {
                        <Rating
                          placeholderRating={Number(rating_book).toFixed(2)}
                          step={1}
                          fractions={10}
                          readonly
                          placeholderSymbol={complete_star_flippy}
                          emptySymbol={empty_star_flippy}
                        />
                      }{" "}
                      {" " + rating_book}{" "}
                      {" (" + (rating_count / 1000).toFixed() + "k)"}
                    </span>
                  </p>
                  {year_published != "nan" && (
                    <span>
                      <p class="detail_page_info_year">
                        {" "}
                        <span class="detail_page_marker">
                          Year Published:{" "}
                        </span>{" "}
                        {" " + year_published?.substring(0, 4)}
                      </p>
                    </span>
                  )}
                </Link>
                <div>
                  <h41 class="nav">
                    {tags_rectangle_box(
                      list_book_genres[card_loop]?.split(","),
                      num_categories
                    )}
                  </h41>
                </div>
              </div>

              <div>
                {/* {book_info?.series_name.length > 0 && (
                  <p class="card-detail-series">
                    {" "}
                    This book is part of{" "}
                    <wired-link
                      elevation="1.3"
                      href={"/series/" + book_info?.series_name.toLowerCase()}
                      target="_blank"
                    >
                      {book_info?.series_name}
                    </wired-link>{" "}
                    series
                  </p>
                )} */}
                <Link
                  to={
                    "/book/" +
                    list_book_name[card_loop]?.replace(/ /g, "-") +
                    "-" +
                    image_book
                  }
                  style={{ textDecoration: "none" }}
                >
                  <p class="card-detail-description"> {description_book} </p>
                </Link>
                {/* 
                <MDBBtn className="cast__books-button" href="#">
                  Buy Now
                </MDBBtn> */}
              </div>
            </div>
          </div>
        );
      } else {
        var description_book = truncate(list_book_description[card_loop], 490);
        var book_name = truncate(list_book_name[card_loop], 48);
        var author_name = truncate(list_author_name[card_loop], 45);
        td.push(
          <div class="card_show_all">
            <div class="row no-gutters mb-4">
              <div class="detail-page-top">
                <Link
                  to={
                    "/book/" +
                    list_book_name[card_loop]?.replace(/ /g, "-") +
                    "-" +
                    image_book
                  }
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={
                      "https://ik.imagekit.io/bookclub/" +
                      image_book +
                      ".jpg?tr=w-300,h-500"
                    }
                    width="300"
                    height="500"
                    alt={book_name}
                  />
                </Link>
              </div>

              <div class="col-sm">
                <Link
                  to={
                    "/book/" +
                    list_book_name[card_loop]?.replace(/ /g, "-") +
                    "-" +
                    image_book
                  }
                  style={{ textDecoration: "none" }}
                >
                  <p class="card-text3"> {book_name} </p>
                  <span class="detail_page_info">
                    {" "}
                    <span class="detail_page_marker">Author: </span>{" "}
                    {" " + author_name}
                  </span>
                  <p class="detail_page_info">
                    {" "}
                    <span class="detail_page_marker">Ratings: </span>{" "}
                    {
                      <Rating
                        placeholderRating={Number(rating_book).toFixed(2)}
                        step={1}
                        fractions={10}
                        readonly
                        placeholderSymbol={complete_star}
                        emptySymbol={empty_star}
                      />
                    }{" "}
                    {" " + rating_book}{" "}
                    {" (" + (rating_count / 1000).toFixed() + "k)"}
                  </p>
                  {year_published != "nan" && (
                    <span>
                      <p class="detail_page_info_year">
                        {" "}
                        <span class="detail_page_marker">
                          Year Published:{" "}
                        </span>{" "}
                        {" " + year_published?.substring(0, 4)}
                      </p>
                    </span>
                  )}
                </Link>
                <div>
                  <h41 class="nav">
                    {tags_rectangle_box(
                      list_book_genres[card_loop]?.split(","),
                      num_categories
                    )}
                  </h41>
                </div>
                <Link
                  to={
                    "/book/" +
                    list_book_name[card_loop]?.replace(/ /g, "-") +
                    "-" +
                    image_book
                  }
                  style={{ textDecoration: "none" }}
                >
                  <p class="card-detail-description"> {description_book} </p>
                </Link>
                {/* <p class="ml-3"> {BookAction(list_data,card_loop)}</p> */}
                {/* 
              <MDBBtn className="cast__books-button" href="#">
                Buy Now
              </MDBBtn> */}
              </div>
            </div>
          </div>
        );
      }
    }

    td2.push(
      <div class="card-group">
        {" "}
        {td}
        <ScrollToTop smooth width={30} color="brown" viewBox="0 0 256 256" />
      </div>
    );
  }

  return (
    <>
      <div className="book_count">{initial_loop + " books in the list"}</div>
      {td2}{" "}
    </>
  );
};

/* Renders ShowAll page after inputting MongoDB data
SampleURL- https://www.bookclub.ai/series/all/Best%20Fantasy%20Series
*/
export function ShowAllListSeriesGenre(MongodbResults, list_id) {
  let list_book = [];
  for (var i = 0; i < 1; i++) {
    if (MongodbResults === undefined) {
      continue;
    } else {
      list_book.push(MongodbResults["books_list"]);
    }
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <ErrorBoundary>
        <div className="show_all_header">{toTitleCase(list_id)}</div>

        {show_all_page(list_book)}
      </ErrorBoundary>
    </div>
  );
}

/* 
Renders carousel on HomePage and Genre Page
SampleUrl:https://www.bookclub.ai/
*/
export default function carousel_render(name, list_data, ref, page_location) {
  return (
    <>
      <div className="nav">
        <carousel_header>
          {name}
          <Link
            to={page_location + name.toLowerCase()}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <span class="name_font">
              {" "}
              Show all <FiChevronRight />
            </span>
          </Link>
        </carousel_header>
      </div>

      <div className="carousel_class">
        <Carousel
          partialVisible={true}
          itemClass="carousel-item-padding-40-px"
          responsive={responsive}
          ssr={true}
          // showDots={true}
          // renderDotsOutside={true}
          keyBoardControl={false}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          minimumTouchDrag={5}
          infinite={true}
        >
          {list_data.map((movie) => carousel_books(movie, ref))}
        </Carousel>
      </div>
    </>
  );
}

/*  
Input Data- Homepage carousel directly from MongoDB
Output Data- Dataset for rendering in MasterFile
Description - Converting KeyValue to List
*/
export const what_is_this_book = (list_data, ref) => {
  let td = [];

  td.push(what_is_this_book_carousel(list_data, ref));

  return td;
};

export const responsive_what_is_this = {
  largescreen: {
    breakpoint: { max: 2500, min: 1200 },
    items: 3.5,
    slidesToSlide: 2.1,
  },
  largescreen2: {
    breakpoint: { max: 1200, min: 992 },
    items: 3.8,
    slidesToSlide: 2.3,
  },

  desktop: {
    breakpoint: { max: 992, min: 768 },
    items: 3.4,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 576 },
    items: 3.2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 3,
    slidesToSlide: 2,
  },
};

/* 
This is for Carousels on Modal
SampleUrl:https://www.bookclub.ai/
*/
export function what_is_this_book_carousel(list_data, ref) {
  var obj = list_data?.[0];
  // console.log(Object.keys(obj).length)
  let result = [];
  let initial_loop = 15;
  for (var i = 0; i < initial_loop; i++) {
    // initial_loop=Object.keys(obj).length
    result.push(obj[i]);
  }

  return (
    <>
      <div className="nav">
        <carousel_header>{"More Like This..."}</carousel_header>
      </div>

      <div className="carousel_class_what_is_book">
        <Carousel
          partialVisible={true}
          itemClass="carousel-item-padding-40-px"
          responsive={responsive_what_is_this}
          ssr={true}
          // showDots={true}
          keyBoardControl={false}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          minimumTouchDrag={5}
          infinite={true}
        >
          {result.map((movie) => carousel_books(movie, ref))}
        </Carousel>
      </div>
    </>
  );
}

export const Carousel_books_what_is_this = (books, ref) => {
  let td = [];

  td.push(
    <>
      <div className="what-is-image">
        <img
          src={
            "https://ik.imagekit.io/bookclub/" + books["image_name2"] + ".jpg"
          }
          alt={books["book_title_goodreads"]}
        ></img>
      </div>
    </>
  );

  return td;
};
